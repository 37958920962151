import classNames from "classnames";

import classes from "./Dropdown.module.scss";
import DropdownIcon from "../DropdownIcon";
import Button from "../Button";

export type DropdownProps = {
  titleContainer?: React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  isOpen: boolean;
};

const Dropdown = ({
  titleContainer = "",
  children,
  disabled = false,
  onClick = () => {},
  isOpen = false,
}: DropdownProps) => (
  <div className={classes.container}>
    <Button
      className={classNames(classes.header, classes.titleContainer)}
      onClick={onClick}
      disabled={disabled}
    >
      {titleContainer}
      <div
        className={classNames(
          classes.dropdownIconWrapper,
          `${isOpen && classes.rotate}`
        )}
      >
        <DropdownIcon />
      </div>
    </Button>
    <div
      className={classNames(classes.data_values, `${isOpen && classes.show}`)}
    >
      {children}
    </div>
  </div>
);
export default Dropdown;
