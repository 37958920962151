import classes from "./NotificationMessageIcon.module.scss";

const NotificationMessageIcon = () => (
  <svg
    className={classes.notificationMessageIcon}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <desc>Notification Message icon</desc>
    <path
      d="M1.70605 5.32353C1.70605 2.64706 3.23547 1.5 5.52958 1.5H13.1766C15.4708 1.5 17.0002 2.64706 17.0002 5.32353V10.6765C17.0002 13.3529 15.4708 14.5 13.1766 14.5H5.52958"
      stroke="var(--primary-color)"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1773 5.70557L10.7838 7.61733C9.99615 8.24439 8.7038 8.24439 7.91615 7.61733L5.53027 5.70557"
      stroke="var(--primary-color)"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.70605 11.4409H6.29429"
      stroke="var(--primary-color)"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.70605 8.38281H4.00017"
      stroke="#817DB5"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotificationMessageIcon;
