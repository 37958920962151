import { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";

import Shipment from "../../../entities/Shipment";
import Button from "../../Common/Button";
import ExternalLink from "../../Common/ExternalLink";
import ExternalLinkIcon from "../../Common/ExternalLinkIcon";
import Paragraph from "../../Common/Paragraph";
import Title from "../../Common/Title";
import classes from "./ShipmentStatus.module.scss";
import ShipmentTimeline from "../ShipmentTimeline";
import CloseIcon from "../../Common/CloseIcon";
import { NotificationButton } from "../../Notifications/NotificationsButton";

export type ShipmentStatusProps = {
  selectedShipment: Shipment;
  setOpenNotificationModalForm: Dispatch<SetStateAction<boolean>>;
};

const ShipmentStatus = ({
  selectedShipment,
  setOpenNotificationModalForm,
}: ShipmentStatusProps) => {
  const [fullHistory, setFullHistory] = useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  const handleOpen = () => setFullHistory(true);
  const handleClose = () => setFullHistory(false);

  return (
    <>
      {fullHistory && (
        <div>
          <Modal open={fullHistory} onClose={handleClose}>
            <Box className={classes.modalBox}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalHeader}
              >
                Shipping Status
                <CloseIcon onClick={handleClose} />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <ShipmentTimeline selectedShipment={selectedShipment} isModal />
              </Typography>
            </Box>
          </Modal>
        </div>
      )}
      <div className={classes.shipmentStatus}>
        <div className={classes.shipmentStatusTitleWrapper}>
          <Title size="m" kind="h3" className={classes.title}>
            Shipping Status
          </Title>
          {!isMobile && (
            <NotificationButton
              selectedShipment={selectedShipment}
              setOpenNotificationModalForm={setOpenNotificationModalForm}
            />
          )}
        </div>
        {!isMobile ? (
          <Paragraph size="xs" className={classes.date}>
            Last update:{" "}
            {selectedShipment?.shipmentDetailList &&
            selectedShipment?.shipmentDetailList?.length > 0
              ? dayjs(selectedShipment.shipmentDetailList.at(0)?.date)
                  .format("MMM DD, YYYY - HH:mm A")
                  .toString()
              : ""}
          </Paragraph>
        ) : null}

        <ShipmentTimeline selectedShipment={selectedShipment} />
        <div className={classes.historyButton}>
          {selectedShipment.shipmentDetailList &&
            selectedShipment.shipmentDetailList.length > 2 && (
              <Button onClick={handleOpen} type="submit" kind="secondary">
                View full shipping history
              </Button>
            )}
        </div>
      </div>
      <div className={classes.furtherDetails}>
        <span>
          Get further shipping details with {selectedShipment.carrier}
        </span>
        <ExternalLink to={selectedShipment.publicUrl} target="_blank">
          <ExternalLinkIcon />
        </ExternalLink>
      </div>
    </>
  );
};

export default ShipmentStatus;
