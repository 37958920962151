import { Alert } from "@mui/material";
import usePortalLookAndFeel from "../../../operations/usePortalLookAndFeel";
import CloseIcon from "../CloseIcon";
import classes from "./TopSmallBanner.module.scss";

export type TopSmallBannerType = {
  onClick: () => void;
};

const TopSmallBanner = ({ onClick }: TopSmallBannerType) => {
  const { portalLookAndFeel } = usePortalLookAndFeel();

  return (
    <Alert icon={false} className={classes.topBanner}>
      <div className={classes.topBannerContent}>
        <div className={classes.topBannerText}>
          {portalLookAndFeel.topBannerText}
        </div>
        <div className={classes.closeButton}>
          <CloseIcon white onClick={onClick} />
        </div>
      </div>
    </Alert>
  );
};
export default TopSmallBanner;
