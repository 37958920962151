import classNames from "classnames";
import Rights from "../Rights";
import classes from "./MinorFooter.module.scss";

export type MinorFooterProps = {
  isLogin?: boolean;
};

const MinorFooter = ({ isLogin = false }: MinorFooterProps) => (
  <div
    className={classNames(classes.minorFooter, {
      [classes.isLogin]: isLogin,
    })}
  >
    <span className={classes.rights}>
      <Rights />
    </span>
  </div>
);

export default MinorFooter;
