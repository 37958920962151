import PortalLookAndFeel from "../entities/PortalLookAndFeel";

export const usePortalLookAndFeelStorage = () => {
  const portalLookAndFeelFromLocalStorage = localStorage.getItem(
    "portalLookAndFeel"
  )
    ? JSON.parse(String(localStorage.getItem("portalLookAndFeel")))
    : null;

  const setPortalLookAndFeelFromStorage = (
    portalLookAndFeelFromCacheStorage: PortalLookAndFeel
  ) => {
    localStorage.setItem(
      "portalLookAndFeel",
      JSON.stringify(portalLookAndFeelFromCacheStorage)
    );
  };

  const hasPortalLookAndFeelFromLocalStorage = Boolean(
    portalLookAndFeelFromLocalStorage
  );

  return {
    portalLookAndFeelFromLocalStorage,
    setPortalLookAndFeelFromStorage,
    hasPortalLookAndFeelFromLocalStorage,
  };
};
