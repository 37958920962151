import classes from "./LoadSpinner.module.scss";

const LoadSpinner = () => {
  return (
    <div className={classes.container} data-testid="spinner">
      <div className={classes["lds-dual-ring"]} />
    </div>
  );
};

export default LoadSpinner;
