import Entity, { EntityOptions } from "./Entity";

export type ShipmentPackageOptions = EntityOptions & {
  id: string;
  shipmentId: string;
  quantity: string;
  item: string;
  description: string;
  imageUrl?: string;
};

export default class ShipmentPackage extends Entity<ShipmentPackageOptions> {
  shipmentId?: string;

  quantity?: string;

  item?: string;

  description?: string;

  imageUrl?: string;

  constructor(options: ShipmentPackageOptions) {
    super(options);
    this.set(options);
  }
}
