import Entity, { EntityOptions } from "./Entity";

export type ApplicationOptions = EntityOptions & {
  lookAndFeelId: string;

  netSuiteAccountId: string;
};

export default class Application extends Entity<ApplicationOptions> {
  lookAndFeelId?: string;

  netSuiteAccountId?: string;

  constructor(options: ApplicationOptions) {
    super(options);
    this.set(options);
  }
}
