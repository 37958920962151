import { useCallback, useContext } from "react";
import ShipmentPackage from "../entities/ShipmentPackage";
import AuthenticationError from "../errors/AuthenticationError";
import { ApplicationContext } from "../providers/ApplicationProvider";
import useNetSuiteService from "./useNetSuiteService";

export type ItemPackageRecord = {
  itemid: string;
};

export type ShipmentPackageRecord = {
  id: string;
  custrecord_ucp_shipment: string;
  custrecord_ucp_package_qty: string;
  custrecord_ucp_package_content_item_id: ItemPackageRecord;
  ["formulatext[{custrecord_ucp_package_image_thumbnail}]"]: string;
  custrecord_ucp_package_content_image_ur?: string;
  custrecord_ucp_package_content_item_desc: string;
};

const generateBaseImageUrl = (netSuiteAccountId: string, imagePath: string) =>
  `https://${netSuiteAccountId}.app.netsuite.com${imagePath}`;

const cleanString = (str: string): string => {
  return str.replace(/&amp;/g, "&");
};

const useShipmentPackageService = () => {
  const { application } = useContext(ApplicationContext);

  const { getFromNetSuite } = useNetSuiteService<ShipmentPackageRecord>({
    resource: "customrecord_ucp_package_contents",
  });

  const getByShipmentIds = useCallback(
    async (shipmentIds: string[]) => {
      if (!application) {
        throw new AuthenticationError();
      }

      const columns = [
        "custrecord_ucp_shipment",
        "custrecord_ucp_package_qty",
        "custrecord_ucp_package_content_item_id.itemid",
        "custrecord_ucp_package_content_item_desc",
        "formulatext[{custrecord_ucp_package_image_thumbnail}]",
      ];

      const domainIncludesPartsExpress =
        !window.location.hostname.includes("workwearsafety");

      if (domainIncludesPartsExpress) {
        columns.push("custrecord_ucp_package_content_image_ur");
      }

      const body = await getFromNetSuite({
        filters: [["custrecord_ucp_shipment", "anyof", ...shipmentIds]],
        columns,
      });

      const { netSuiteAccountId } = application;

      const results = body.results.map((packageContent) => {
        const packageContentImageUrl =
          packageContent?.custrecord_ucp_package_content_image_ur;
        const productImage =
          packageContent[
            "formulatext[{custrecord_ucp_package_image_thumbnail}]"
          ];
        let imageUrl =
          packageContentImageUrl &&
          !packageContentImageUrl?.toLowerCase().includes("https")
            ? generateBaseImageUrl(
                String(netSuiteAccountId),
                String(packageContentImageUrl)
              )
            : packageContentImageUrl ?? "";

        if (productImage && !imageUrl) {
          imageUrl = generateBaseImageUrl(
            String(netSuiteAccountId),
            productImage || ""
          );
        }

        return new ShipmentPackage({
          id: packageContent.id,
          shipmentId: packageContent.custrecord_ucp_shipment,
          quantity: packageContent.custrecord_ucp_package_qty,
          item: packageContent.custrecord_ucp_package_content_item_id.itemid,
          imageUrl: cleanString(imageUrl),
          description: packageContent.custrecord_ucp_package_content_item_desc,
        });
      });

      return { ...body, results };
    },
    [application, getFromNetSuite]
  );

  return { getByShipmentIds };
};

export default useShipmentPackageService;
