import { useCallback, useMemo, useContext } from "react";
import { httpsCallable } from "firebase/functions";

import useFunctionsService from "./useFunctionsService";
import AuthenticationError from "../errors/AuthenticationError";
import UnexpectedError from "../errors/UnexpectedError";
import NetSuiteError, { NetSuiteErrorBody } from "../errors/NetSuiteError";
import { CredentialContext } from "../providers/CredentialProvider";

type Request<P, B> = {
  headers?: { [header: string]: string };
  method: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  params?: P;
  body?: B;
};

type Response<T> = {
  statusCode: number;
  body: T;
};

type Columns = string[];
type BooleanOperator = "AND" | "OR";
type Filter = string[] | (BooleanOperator | Filter)[];
type Sort = {
  field: string;
  order: "ASC" | "DESC";
};

type GetParams = {
  columns?: Columns;
  filters?: Filter[];
  sort?: Sort;
  limit?: number;
  page?: number;
};

type GetByIdParams = {
  columns: Columns;
  subrecords?: {
    resource: string;
    columns?: Columns;
    sort?: Sort;
    limit?: number;
  }[];
};

type GetBody<T> = {
  results: T[];
  page: number;
  limit: number;
  total: number;
};

const useNetSuiteService = <T>({ resource }: { resource: string }) => {
  const { functions } = useFunctionsService();
  const { credential } = useContext(CredentialContext);

  const token = useMemo(() => credential?.token, [credential]);

  const callNetSuiteFunction = useMemo(
    () => httpsCallable(functions, "core-callNetSuite"),
    [functions]
  );

  const validate = useCallback(() => {
    if (!token) {
      throw new AuthenticationError(
        "Missing credentials",
        "It seems like authentication wasn't done. Please authenticate to get credentials."
      );
    }
  }, [token]);

  const callNetSuite = useCallback(
    async <P, B, R>(request: Request<P, B>) => {
      let result;

      validate();

      try {
        result = await callNetSuiteFunction({
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (e) {
        const error = e as Error;
        throw new UnexpectedError(
          "Unexpected Error",
          "An unexpected error occurred, please try again later",
          error
        );
      }

      const response = result.data as Response<R | NetSuiteErrorBody>;
      const { statusCode, body } = response;

      if (statusCode >= 400) {
        const errorBody = body as NetSuiteErrorBody;
        throw new NetSuiteError(
          "NetSuite Error",
          errorBody.message,
          undefined,
          statusCode,
          errorBody
        );
      }

      return body as R;
    },
    [validate, token, callNetSuiteFunction]
  );

  const getFromNetSuite = useCallback(
    async (params: GetParams): Promise<GetBody<T>> =>
      callNetSuite<GetParams, undefined, GetBody<T>>({
        method: "GET",
        url: `${resource}`,
        params,
      }),
    [callNetSuite, resource]
  );

  const getByIdFromNetSuite = useCallback(
    async (id: string, params: GetByIdParams) =>
      callNetSuite<GetByIdParams, undefined, T>({
        method: "GET",
        url: `${resource}/${id}`,
        params,
      }),
    [callNetSuite, resource]
  );

  return { getFromNetSuite, getByIdFromNetSuite };
};

export default useNetSuiteService;
