import { createContext, useMemo, useState, useEffect, ReactNode } from "react";

import PortalLookAndFeel from "../../entities/PortalLookAndFeel";

const portalLookAndFeelFromLocalStorage = localStorage.getItem(
  "portalLookAndFeel"
)
  ? JSON.parse(String(localStorage.getItem("portalLookAndFeel")))
  : null;

const initialValue =
  portalLookAndFeelFromLocalStorage ?? new PortalLookAndFeel();

type PortalLookAndFeelContextValue = {
  portalLookAndFeel: PortalLookAndFeel;
  setPortalLookAndFeel: (prevState: PortalLookAndFeel) => void;
};

export const PortalLookAndFeelContext =
  createContext<PortalLookAndFeelContextValue>({
    portalLookAndFeel: new PortalLookAndFeel(),
    setPortalLookAndFeel: () => {},
  });

const PortalLookAndFeelProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: PortalLookAndFeelContextValue;
}) => {
  const [portalLookAndFeel, setPortalLookAndFeel] =
    useState<PortalLookAndFeel>(initialValue);

  const actualValue = useMemo(
    () =>
      value || {
        portalLookAndFeel,
        setPortalLookAndFeel,
      },
    [portalLookAndFeel, value]
  );

  const actualPortalLookAndFeel = useMemo(
    () => actualValue.portalLookAndFeel,
    [actualValue]
  );

  useEffect(() => {
    const root = document.documentElement;
    if (root) {
      root?.style.setProperty(
        "--headings-color",
        String(actualPortalLookAndFeel.headingsColor)
      );
      root?.style.setProperty(
        "--primary-color",
        String(actualPortalLookAndFeel.primaryColor)
      );
      root?.style.setProperty(
        "--primary-dark-color",
        String(actualPortalLookAndFeel.primaryDarkColor)
      );
      root?.style.setProperty(
        "--primary-darker-color",
        String(actualPortalLookAndFeel.primaryDarkerColor)
      );
      root?.style.setProperty(
        "--primary-darkest-color",
        String(actualPortalLookAndFeel.primaryDarkestColor)
      );
      root?.style.setProperty(
        "--primary-light-color",
        String(actualPortalLookAndFeel.primaryLightColor)
      );
      root?.style.setProperty(
        "--primary-lighter-color",
        String(actualPortalLookAndFeel.primaryLighterColor)
      );
      root?.style.setProperty(
        "--primary-lightest-color",
        String(actualPortalLookAndFeel.primaryLightestColor)
      );
      root?.style.setProperty(
        "--text-color",
        String(actualPortalLookAndFeel.textColor)
      );
      root?.style.setProperty(
        "--navbar-background",
        String(actualPortalLookAndFeel.navbarBackground)
      );
      root?.style.setProperty(
        "--navbar-link-color",
        String(actualPortalLookAndFeel.navbarLinkColor)
      );
      root?.style.setProperty(
        "--home-title-color",
        String(actualPortalLookAndFeel.homeTitleColor)
      );
      root?.style.setProperty(
        "--home-title-color-shipping",
        String(actualPortalLookAndFeel.homeTitleColorShipping)
      );
    }
  }, [actualPortalLookAndFeel]);

  return (
    <PortalLookAndFeelContext.Provider value={actualValue}>
      {children}
    </PortalLookAndFeelContext.Provider>
  );
};

export default PortalLookAndFeelProvider;
