import classes from "./ExternalLinkIcon.module.scss";

const ExternalLinkIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="shipping details link"
      role="img"
    >
      <desc>External link icon</desc>
      <rect
        width="26"
        height="26"
        role="img"
        rx="8"
        aria-label="shipping details link"
        className={classes.background}
      >
        <desc>External link</desc>
      </rect>
      <path
        d="M13.1464 12.1464C12.9512 12.3417 12.9512 12.6583 13.1464 12.8536C13.3417 13.0488 13.6583 13.0488 13.8536 12.8536L13.1464 12.1464ZM17.9536 8.75355C18.1488 8.55829 18.1488 8.24171 17.9536 8.04645C17.7583 7.85118 17.4417 7.85118 17.2464 8.04645L17.9536 8.75355ZM17.5 10.4C17.5 10.6761 17.7239 10.9 18 10.9C18.2761 10.9 18.5 10.6761 18.5 10.4H17.5ZM18 8H18.5C18.5 7.72386 18.2761 7.5 18 7.5V8ZM15.6 7.5C15.3239 7.5 15.1 7.72386 15.1 8C15.1 8.27614 15.3239 8.5 15.6 8.5V7.5ZM12.5 8.5C12.7761 8.5 13 8.27614 13 8C13 7.72386 12.7761 7.5 12.5 7.5V8.5ZM18.5 13.5C18.5 13.2239 18.2761 13 18 13C17.7239 13 17.5 13.2239 17.5 13.5H18.5ZM13.8536 12.8536L17.9536 8.75355L17.2464 8.04645L13.1464 12.1464L13.8536 12.8536ZM18.5 10.4V8H17.5V10.4H18.5ZM18 7.5H15.6V8.5H18V7.5ZM12.5 7.5H11.5V8.5H12.5V7.5ZM11.5 7.5C10.2007 7.5 9.16109 7.7568 8.45895 8.45895C7.7568 9.16109 7.5 10.2007 7.5 11.5H8.5C8.5 10.2993 8.7432 9.58891 9.16605 9.16605C9.58891 8.7432 10.2993 8.5 11.5 8.5V7.5ZM7.5 11.5V14.5H8.5V11.5H7.5ZM7.5 14.5C7.5 15.7993 7.7568 16.8389 8.45895 17.5411C9.16109 18.2432 10.2007 18.5 11.5 18.5V17.5C10.2993 17.5 9.58891 17.2568 9.16605 16.8339C8.7432 16.4111 8.5 15.7007 8.5 14.5H7.5ZM11.5 18.5H14.5V17.5H11.5V18.5ZM14.5 18.5C15.7993 18.5 16.8389 18.2432 17.5411 17.5411C18.2432 16.8389 18.5 15.7993 18.5 14.5H17.5C17.5 15.7007 17.2568 16.4111 16.8339 16.8339C16.4111 17.2568 15.7007 17.5 14.5 17.5V18.5ZM18.5 14.5V13.5H17.5V14.5H18.5Z"
        fill="white"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
