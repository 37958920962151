import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useMemo } from "react";

import classes from "./ShipmentComponent.module.scss";
import Shipment from "../../../entities/Shipment";
import StatusProgress from "../StatusProgress";
import Button from "../../Common/Button";
import { stringify } from "../../../enums/ShipmentStatus";

export type ShipmentPackageProps = {
  shipment: Shipment;
  selected?: boolean;
  onClick?: (shipment: Shipment) => void;
};

const ShipmentComponent = ({
  shipment,
  selected = false,
  onClick,
}: ShipmentPackageProps) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  const { latestEvent, status, trackingNumber, expectedDelivery } = shipment;
  const expectedDeliveryDate = new Date(expectedDelivery ?? new Date());
  let month;
  let day;
  if (expectedDelivery) {
    month = expectedDeliveryDate.toLocaleString("default", {
      month: "short",
    });
    day = expectedDeliveryDate.toLocaleString("default", {
      day: "2-digit",
    });
  }

  const statusContainer = useMemo(
    () => status && <div className={classes.status}>{stringify(status)}</div>,
    [status]
  );

  return (
    <>
      <Button
        className={classNames(classes.package, { [classes.clicked]: selected })}
        onClick={onClick ? () => onClick(shipment) : undefined}
        type="button"
      >
        <div className={classes.statusIcon}>
          <StatusProgress status={shipment.status} />
        </div>
        <div className={classes.packagesWrapper}>
          {!isMobile && statusContainer}

          <div className={classes.statusDetails}>
            {!isMobile && latestEvent && (
              <div className={classes.valueContainer}>
                <span className={classes.valueLabel}>Status:&nbsp;</span>
                <div className={classes.value}>{latestEvent}</div>
              </div>
            )}

            {isMobile && (
              <div className={classes.statusValue}>
                {statusContainer}
                {latestEvent && (
                  <div className={classes.latestEvent}>
                    <li>{latestEvent}</li>
                  </div>
                )}
              </div>
            )}

            <div className={classes.valueContainer}>
              <span className={classes.valueLabel}>
                {!isMobile ? "Tracking Number:" : "Tracking N°:"}
                &nbsp;
              </span>
              <span className={classes.value}>{trackingNumber}</span>
            </div>
            {expectedDelivery && (
              <div className={classes.valueContainer}>
                <span className={classes.valueLabel}>Arriving:&nbsp;</span>
                <span className={classes.value}>
                  <span className={classes.date}>
                    {month}&nbsp;{day}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      </Button>
    </>
  );
};

export default ShipmentComponent;
