import classNames from "classnames";
import classes from "./CloseIcon.module.scss";

export type CloseIconProps = {
  onClick: () => void;
  white?: boolean;
};

const CloseIcon = ({ onClick, white = false }: CloseIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={classes.closeIcon}
      role="img"
    >
      <desc>Close Icon</desc>
      <path
        d="M12 4L4 12"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={classNames(classes.lines, { [classes.white]: white })}
      />
      <path
        d="M4 4L12 12"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={classNames(classes.lines, { [classes.white]: white })}
      />
    </svg>
  );
};

export default CloseIcon;
