/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint no-use-before-define: 0 */
import {
  Page,
  Text,
  View,
  Link,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import Shipment from "../../../entities/Shipment";
import PortalLookAndFeel from "../../../entities/PortalLookAndFeel";

const MyDocument: any = Document;
const MyPage: any = Page;
const MyView: any = View;
const MyText: any = Text;
const MyLink: any = Link;

export type ShipmentTimelineProps = {
  selectedShipment: Shipment;
  portalLookAndFeel: PortalLookAndFeel;
};

const styles = StyleSheet.create({
  page: { padding: "50px" },
  mainTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: 15,
  },
  title: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: 15,
  },
  shippedItemsTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: 10,
    marginBottom: 5,
  },
  shippedItemsSubtitle: {
    fontWeight: "bold",
    fontSize: "11px",
    marginBottom: 15,
    color: "#7f7d92",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "11px",
    marginBottom: 5,
  },
  paragraph: {
    fontWeight: "normal",
    fontSize: "10px",
    marginBottom: 5,
  },
  description: {
    fontWeight: "normal",
    fontSize: "9px",
    marginBottom: 5,
    color: "#7f7d92",
  },
  quantity: {
    color: "#7f7d92",
  },
  listDot: { marginRight: 20, color: "#7f7d92", marginBottom: 12 },
  divider: {
    borderLeft: "1px solid #d9d8e9",
    height: "14px",
    marginLeft: 3,
    marginBottom: 15,
  },
  statusText: { fontWeight: "normal", fontSize: "11px" },
  date: { color: "#7f7d92", fontSize: "10px", marginBottom: 5 },
  table: {
    width: "100%",
    marginBottom: 30,
    backgroundColor: "#e3e3e3",
    border: "1px solid #000000",
    borderRadius: "5px",
    overflow: "hidden",
  },
  row: { display: "flex", flexDirection: "row", alignItems: "center" },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #000000",
  },
  tableRowDate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
    borderBottom: "none",
  },
  colLeft: {
    width: "160px",
    fontSize: "11px",
    backgroundColor: "#e3e3e3",
    padding: "5px",
  },
  colLeftTop: {
    width: "160px",
    fontSize: "11px",
    backgroundColor: "#e3e3e3",
    padding: "5px",
    borderTopLeftRadius: "5px",
  },
  colLeftBottom: {
    width: "160px",
    fontSize: "11px",
    backgroundColor: "#e3e3e3",
    padding: "5px",
    borderBottomLeftRadius: "5px",
  },
  colRight: {
    display: "flex",
    justifyContent: "center",
    width: "calc(100% - 160px)",
    fontSize: "11px",
    padding: "5px",
    borderLeft: "1px solid #000000",
    backgroundColor: "#ffffff",
    minHeight: "25px",
  },
  colRightTop: {
    display: "flex",
    justifyContent: "center",
    width: "calc(100% - 160px)",
    fontSize: "11px",
    padding: "5px",
    borderLeft: "1px solid #000000",
    backgroundColor: "#ffffff",
    borderTopRightRadius: "5px",
    minHeight: "25px",
  },
  colRightBottom: {
    display: "flex",
    justifyContent: "center",
    width: "calc(100% - 160px)",
    fontSize: "11px",
    padding: "5px",
    borderLeft: "1px solid #000000",
    backgroundColor: "#ffffff",
    borderBottomRightRadius: "5px",
    minHeight: "25px",
  },
  packageWrapper: { marginBottom: 15 },
  shippedItems: { fontSize: "11px", color: "#7f7d92" },
  packageTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    border: "1px solid #000000",
    borderBottom: "none",
    borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",
    backgroundColor: "#e3e3e3",
  },
  packageTable: {
    borderLeft: "1px solid #000000",
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
    borderBottomRightRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  packageTableBody: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderTop: "1px solid #000000",
  },
  itemCol: { width: "30%", borderRight: "1px solid #000000" },
  quantityCol: { width: "15%", borderRight: "1px solid #000000" },
  descriptionCol: { width: "65%" },
  colHeader: { fontSize: "11px" },
  textCell: { padding: "5px" },
  trackYourOrder: {
    padding: "10px",
    backgroundColor: "#000000",
    borderRadius: "5px",
    width: "200px",
    color: "#ffffff",
    textDecoration: "none",
    textAlign: "center",
    textTransform: "capitalize",
    marginTop: "50px",
  },
  trackYourOrderWrapper: { display: "flex", alignItems: "center" },
});

const ShipmentStatusPdf = ({
  selectedShipment,
  portalLookAndFeel,
}: ShipmentTimelineProps) => {
  const { shipmentDetailList, shipmentPackages } = selectedShipment;
  const trackingUrl = window.location.href;

  return (
    <>
      <MyDocument>
        <MyPage size="A4" style={styles.page}>
          <MyView>
            <MyText style={styles.mainTitle}>
              {portalLookAndFeel.companyName}
            </MyText>
          </MyView>
          <MyView>
            <MyText style={styles.title}>Shipping Details</MyText>
            <MyView style={styles.table}>
              <MyView style={styles.tableRow}>
                <MyView style={styles.colLeftTop}>
                  <MyView>
                    <MyText>Carrier</MyText>
                  </MyView>
                </MyView>
                <MyView style={styles.colRightTop}>
                  <MyText>{selectedShipment.carrier}</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.tableRow}>
                <MyView style={styles.colLeft}>
                  <MyView>
                    <MyText>Tracking Number</MyText>
                  </MyView>
                </MyView>
                <MyView style={styles.colRight}>
                  <MyText>{selectedShipment.trackingNumber}</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.tableRow}>
                <MyView style={styles.colLeft}>
                  <MyView>
                    <MyText>Order Number</MyText>
                  </MyView>
                </MyView>
                <MyView style={styles.colRight}>
                  <MyText>{selectedShipment.order}</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.tableRow}>
                <MyView style={styles.colLeft}>
                  <MyView>
                    <MyText>Shipping Address</MyText>
                  </MyView>
                </MyView>
                <MyView style={styles.colRight}>
                  <MyText>{selectedShipment.shipAddress}</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.tableRowDate}>
                <MyView style={styles.colLeftBottom}>
                  <MyView>
                    <MyText>Delivery Date</MyText>
                  </MyView>
                </MyView>
                <MyView style={styles.colRightBottom}>
                  <MyText>
                    {selectedShipment.expectedDelivery
                      ? `${dayjs(selectedShipment.expectedDelivery)
                          .format("MMM DD, YYYY - HH:mm")
                          .toString()}`
                      : ""}
                  </MyText>
                </MyView>
              </MyView>
            </MyView>
            <MyView>
              <MyText style={styles.title}>Shipping Status</MyText>
            </MyView>
            {shipmentDetailList?.map((status) => {
              return (
                <>
                  <MyView style={styles.row}>
                    <MyText style={styles.listDot}>&#x2022;</MyText>
                    <MyView>
                      <MyText style={styles.date}>
                        {status?.date
                          ? `${dayjs(status.date)
                              .format("MMM DD, YYYY - HH:mm")
                              .toString()} - ${status.location}`
                          : ""}
                      </MyText>
                      <MyText style={styles.statusText}>
                        {status.message}
                      </MyText>
                    </MyView>
                  </MyView>
                  <MyView style={styles.divider} />
                </>
              );
            })}
            <MyView>
              <MyText style={styles.shippedItemsTitle}>Shipped Items</MyText>
            </MyView>
            <MyView style={styles.shippedItemsSubtitle}>
              <MyText>
                Shipped in this package:&nbsp;
                {selectedShipment.shipmentPackages?.length}&nbsp;items
              </MyText>
            </MyView>
            <MyView style={styles.packageTableHeader}>
              <MyView style={styles.itemCol}>
                <MyView style={styles.colHeader}>
                  <MyText style={styles.textCell}>Item</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.quantityCol}>
                <MyView style={styles.colHeader}>
                  <MyText style={styles.textCell}>Quantity</MyText>
                </MyView>
              </MyView>
              <MyView style={styles.descriptionCol}>
                <MyView style={styles.colHeader}>
                  <MyText style={styles.textCell}>Description</MyText>
                </MyView>
              </MyView>
            </MyView>
            <MyView style={styles.packageTable}>
              {shipmentPackages?.map((content) => {
                return (
                  <>
                    <MyView style={styles.packageTableBody}>
                      <MyView style={styles.itemCol}>
                        <MyView style={styles.colHeader}>
                          <MyText style={styles.textCell}>
                            {content.item}
                          </MyText>
                        </MyView>
                      </MyView>
                      <MyView style={styles.quantityCol}>
                        <MyView style={styles.colHeader}>
                          <MyText style={styles.textCell}>
                            {content.quantity}
                          </MyText>
                        </MyView>
                      </MyView>
                      <MyView style={styles.descriptionCol}>
                        <MyView style={styles.colHeader}>
                          <MyText style={styles.textCell}>
                            {content.description}
                          </MyText>
                        </MyView>
                      </MyView>
                    </MyView>
                  </>
                );
              })}
            </MyView>
          </MyView>
          <MyView style={styles.trackYourOrderWrapper}>
            <MyLink style={styles.trackYourOrder} src={trackingUrl}>
              Track your order
            </MyLink>
          </MyView>
        </MyPage>
      </MyDocument>
    </>
  );
};

export default ShipmentStatusPdf;
