import { v4 as uuidv4 } from "uuid";

export type EntityOptions = { id?: string; [key: string]: unknown };

export default abstract class Entity<O extends EntityOptions> {
  id: string;

  [key: string]: unknown;

  constructor(options: O = {} as O) {
    if (options.id) {
      this.id = options.id;
    } else {
      this.id = uuidv4();
    }
  }

  set(options: O) {
    Object.keys(options).forEach((key) => {
      this[key] = options[key];
    });
  }
}
