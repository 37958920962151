import { FC } from "react";
import { isEmpty } from "lodash";

import classes from "./UnderMaintenance.module.scss";

type UnderMaintenanceProps = {
  underMaintenanceMessage?: string;
};

const UnderMaintenance: FC<UnderMaintenanceProps> = ({
  underMaintenanceMessage,
}) => (
  <div className={classes.container}>
    <h1>
      {!isEmpty(underMaintenanceMessage)
        ? underMaintenanceMessage
        : `We're currently undergoing maintenance to improve your experience. Please
    check back soon. We apologize for any inconvenience.`}
    </h1>
  </div>
);

export default UnderMaintenance;
