export default abstract class BaseError<T> extends Error {
  title: string;

  partialResult?: T;

  constructor(
    title = "Unexpected Error",
    message = "An unexpected error occurred, please try again later",
    cause?: Error,
    partialResult?: T
  ) {
    super(message);
    this.title = title;
    this.cause = cause;
    this.partialResult = partialResult;
  }
}
