import Entity, { EntityOptions } from "./Entity";

export type ShipmentDetailOptions = EntityOptions & {
  shipmentId: string;
  message: string;
  status: string;
  date: string;
  location: string;
};

export default class ShipmentDetail extends Entity<ShipmentDetailOptions> {
  shipmentId?: string;

  message?: string;

  status?: string;

  date?: string;

  location?: string;

  constructor(options: ShipmentDetailOptions) {
    super(options);
    this.set(options);
  }
}
