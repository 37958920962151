import ShipmentStatus from "../enums/ShipmentStatus";
import Entity, { EntityOptions } from "./Entity";
import Shipment from "./Shipment";

export type NotificationsFormOptions = EntityOptions & {
  channel: string;
  name: string;
  email: string;
  phone?: string;
  shipmentStatuses: ShipmentStatus[];
  selectedShipment: Shipment;
};

export default class NotificationsForm extends Entity<NotificationsFormOptions> {
  channel?: string;

  name?: string;

  email?: string;

  phone?: string;

  shipmentStatuses?: [];

  selectedShipment: Shipment;

  constructor(options: NotificationsFormOptions) {
    super(options);
    this.set(options);
    this.selectedShipment = options.selectedShipment;
  }
}
