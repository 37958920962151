import { createContext, useMemo, useState, ReactNode } from "react";

import Application from "../../entities/Application";

// TODO: Consider creating a generic context to avoid code repetition.
export const ApplicationContext = createContext<{
  application?: Application;
  setApplication: (prevState?: Application) => void;
}>({ setApplication: () => {} });

const ApplicationProvider = ({ children }: { children: ReactNode }) => {
  const [application, setApplication] = useState<Application>();

  const value = useMemo(
    () => ({
      application,
      setApplication,
    }),
    [application]
  );

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
