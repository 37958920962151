import BaseError from "./BaseError";

export type NetSuiteErrorBody = {
  message: string;
  errors?: { message: string }[];
};

export default class NetSuiteError extends BaseError<NetSuiteErrorBody> {
  statusCode?: number;

  constructor(
    title = "NetSuite Error",
    message = "An error occurred while calling NetSuite, please try again later",
    cause?: Error,
    statusCode?: number,
    partialResult?: NetSuiteErrorBody
  ) {
    super(title, message, cause, partialResult);
    this.statusCode = statusCode;
  }
}
