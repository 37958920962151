import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useCallback, useMemo, useContext } from "react";

import NotificationsForm from "../entities/NotificationsForm";
import { CredentialContext } from "../providers/CredentialProvider";
import useFunctionsService from "./useFunctionsService";
import ShipmentNotification from "../entities/ShipmentNotification";

const useNotificationsFormService = () => {
  const { functions } = useFunctionsService();
  const { credential } = useContext(CredentialContext);
  const token = useMemo(() => credential?.token, [credential]);

  const submitNotificationsFormFunction: HttpsCallable<
    {
      token: string | undefined;
      payload: NotificationsForm;
    },
    {
      result: ShipmentNotification;
    }
  > = useMemo(
    () => httpsCallable(functions, "unlockshippings-submitNotificationsForm"),
    [functions]
  );
  const submitNotificationsForm = useCallback(
    async (notificationForm: NotificationsForm) => {
      try {
        const submitNotifications = await submitNotificationsFormFunction({
          token,
          payload: notificationForm,
        });
        const newNotification = submitNotifications.data.result;
        return newNotification;
      } catch (e) {
        console.error(e);
        throw new Error(
          "Unexpected error while authenticating, try again later!"
        );
      }
    },
    [token, submitNotificationsFormFunction]
  );
  return { submitNotificationsForm };
};

export default useNotificationsFormService;
