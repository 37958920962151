import axios from "axios";
import { useCallback, useMemo } from "react";

const useStorageService = () => {
  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_CLOUD_STORAGE_BASE_URL,
      }),
    []
  );

  const fetch = useCallback(
    async <T>(path: string) => (await axiosInstance.get(path)).data as T,
    [axiosInstance]
  );

  return { fetch };
};

export default useStorageService;
