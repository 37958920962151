import BaseError from "./BaseError";
import UnexpectedError from "./UnexpectedError";

const DEFAULT_TITLE = "Multiple Errors";

export default class MultipleError<T> extends BaseError<T> {
  causes?: Error[];

  constructor(
    causes: BaseError<unknown>[],
    title?: string,
    message?: string,
    partialResult?: T
  ) {
    const [cause] = causes;
    let actualTitle = title;
    let actualMessage = message;

    if (causes.length === 1) {
      actualTitle = cause.title;
      actualMessage = cause.message;
    } else if (causes.length > 1) {
      actualTitle = actualTitle || DEFAULT_TITLE;
      actualMessage =
        actualMessage ||
        causes.map(({ message: errorMessage }) => errorMessage).join("\n");
    } else {
      throw new UnexpectedError<undefined>(
        "No causes provided",
        "At least one cause is required"
      );
    }

    super(actualTitle, actualMessage, cause, partialResult);
    this.causes = causes;
  }
}
