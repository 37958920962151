import { createContext, ReactNode, useMemo, useState } from "react";

import Credential from "../../entities/Credential";

export const CredentialContext = createContext<{
  credential?: Credential;
  setCredential: (prevState?: Credential) => void;
}>({ setCredential: () => {} });

const CredentialProvider = ({ children }: { children: ReactNode }) => {
  const [credential, setCredential] = useState<Credential>();

  const value = useMemo(
    () => ({
      credential,
      setCredential,
    }),
    [credential]
  );

  return (
    <CredentialContext.Provider value={value}>
      {children}
    </CredentialContext.Provider>
  );
};

export default CredentialProvider;
