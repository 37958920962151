import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import classes from "./Link.module.scss";

export type LinkKind = "primary" | "secondary";

export type LinkProps = {
  children: React.ReactNode;
  className?: string;
  to: string;
  underlined?: boolean;
  bold?: boolean;
  kind?: LinkKind;
};

const Link = ({
  to,
  className = "",
  children,
  underlined = false,
  bold = false,
  kind = "primary",
}: LinkProps) => {
  return (
    <RouterLink
      className={classNames(classes.link, classes[kind], className, {
        [classes.underlined]: underlined,
        [classes.bold]: bold,
      })}
      to={to}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
