import classNames from "classnames";
import dayjs from "dayjs";
import Shipment from "../../../entities/Shipment";
import Paragraph from "../../Common/Paragraph";
import classes from "./ShipmentTimeline.module.scss";

export type ShipmentTimelineProps = {
  selectedShipment: Shipment;
  isModal?: boolean;
};

const ShipmentTimeline = ({
  selectedShipment,
  isModal = false,
}: ShipmentTimelineProps) => {
  let { shipmentDetailList: shipmentStatusList } = selectedShipment;
  if (!isModal) {
    shipmentStatusList = shipmentStatusList?.slice(0, 2);
  }

  const { carrierIsNotSupported } = selectedShipment;

  return (
    <div
      className={classNames(classes.timelineContainer, {
        [classes.isModal]: isModal,
      })}
    >
      <div className={classes.timeline}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {shipmentStatusList ? (
          // eslint-disable-next-line no-nested-ternary
          shipmentStatusList.length ? (
            shipmentStatusList.map((status, index) => {
              const isInTheStatusList =
                shipmentStatusList &&
                shipmentStatusList.length > 1 &&
                index !== shipmentStatusList.length - 1;
              return (
                <>
                  <div className={classes.record}>
                    <li className={classes.bullet} />
                    <div className={classes.statusInfo}>
                      <Paragraph size="xs" className={classes.date}>
                        {status?.date
                          ? `${dayjs(status.date)
                              .format("MMM DD, YYYY - HH:mm A")
                              .toString()} -  
                            ${status.location}`
                          : ""}
                      </Paragraph>
                      <Paragraph size="s" className={classes.statusInfo}>
                        {status.message}
                      </Paragraph>
                    </div>
                  </div>
                  {isInTheStatusList && (
                    <div className={classes.divider}> </div>
                  )}
                </>
              );
            })
          ) : carrierIsNotSupported ? (
            <div className={classes.noStatus}>
              <span>
                Carrier not supported, please click on the link below to go to
                carrier’s page.
              </span>
            </div>
          ) : (
            <div className={classes.noStatus}>
              <span>No status yet</span>
            </div>
          )
        ) : (
          <div className={classes.noStatus}>
            <span>Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentTimeline;
