import classes from "./DropdownIcon.module.scss";

const DropdownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="25"
      viewBox="0 96 960 960"
      width="25"
      className={classes.arrowColor}
    >
      <path d="M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z" />
    </svg>
  );
};

export default DropdownIcon;
