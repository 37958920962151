import classNames from "classnames";
import ShipmentStatus from "../../../enums/ShipmentStatus";
import classes from "./StatusProgress.module.scss";

export type StatusProgressProps = {
  status?: ShipmentStatus;
};

const StatusProgress = ({
  status = ShipmentStatus.In_Transit,
}: StatusProgressProps) => {
  let progress;

  switch (status) {
    case ShipmentStatus.Pre_Transit:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.svg}
          >
            <desc>Status Pre-Transit Icon</desc>
            <ellipse cx="23.9998" cy="24" rx="23.9998" ry="24" fill="#FAFBFC" />
            <path
              d="M24.0002 0.880701C24.0002 0.394304 24.3946 -0.00168816 24.8807 0.0161532C27.7319 0.120833 30.5442 0.733218 33.1845 1.82689C36.0963 3.03301 38.742 4.80083 40.9706 7.02945C43.1992 9.25805 44.967 11.9038 46.1731 14.8156C47.2668 17.456 47.8792 20.2682 47.9838 23.1195C48.0017 23.6056 47.6057 24 47.1193 24C46.6329 24 46.2404 23.6055 46.2212 23.1195C46.1174 20.4996 45.551 17.9163 44.5458 15.4897C43.4282 12.7916 41.7902 10.34 39.7251 8.27495C37.6601 6.2099 35.2085 4.57181 32.5105 3.45422C30.0838 2.44908 27.5005 1.88265 24.8807 1.77884C24.3946 1.75958 24.0002 1.3671 24.0002 0.880701Z"
              fill="#E9ECEF"
              className={classes.line}
            />
          </svg>
          <div className={classNames(classes.iconWrapper, classes.preTransit)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.In_Transit:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.svg}
            role="img"
          >
            <desc>Status In Transit Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              className={classes.line}
              d="M24 0.880701C24 0.394304 24.3945 -0.00168816 24.8805 0.0161494C29.3188 0.179056 33.6313 1.57089 37.3337 4.04473C41.2805 6.68188 44.3566 10.4302 46.1731 14.8156C47.9896 19.201 48.4649 24.0266 47.5388 28.6822C46.6128 33.3377 44.327 37.6141 40.9706 40.9706C37.6141 44.327 33.3377 46.6128 28.6822 47.5388C24.0266 48.4649 19.201 47.9896 14.8156 46.1731C10.4302 44.3566 6.68188 41.2805 4.04473 37.3337C1.57089 33.6313 0.179056 29.3188 0.0161534 24.8805C-0.00168403 24.3944 0.394308 24 0.880705 24C1.36711 24 1.75959 24.3945 1.77884 24.8805C1.94086 28.9703 3.22902 32.9424 5.50929 36.3551C7.95289 40.0122 11.4261 42.8626 15.4897 44.5458C19.5532 46.229 24.0247 46.6694 28.3385 45.8113C32.6524 44.9532 36.6149 42.8352 39.7251 39.7251C42.8352 36.6149 44.9532 32.6524 45.8113 28.3385C46.6694 24.0247 46.229 19.5532 44.5458 15.4897C42.8626 11.4261 40.0122 7.9529 36.3551 5.50929C32.9424 3.22901 28.9703 1.94086 24.8805 1.77884C24.3945 1.75958 24 1.3671 24 0.880701Z"
            />
          </svg>
          <div className={classes.iconWrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.Out_For_Delivery:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Out For Delivery Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M24 0.880701C24 0.394304 24.3946 -0.00168816 24.8807 0.0161456C30.926 0.237925 36.6777 2.73657 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.6777 45.2634 30.926 47.7621 24.8807 47.9839C24.3946 48.0017 24 47.6057 24 47.1193C24 46.6329 24.3946 46.2404 24.8806 46.2212C30.4586 46.0003 35.7625 43.6876 39.7251 39.7251C43.8956 35.5545 46.2386 29.898 46.2386 24C46.2386 18.102 43.8956 12.4455 39.7251 8.27494C35.7626 4.31243 30.4587 1.9997 24.8806 1.77883C24.3946 1.75959 24 1.3671 24 0.880701Z"
              fill="#E9ECEF"
              className={classes.line}
            />
          </svg>
          <div className={classes.iconWrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.Delivered:
    case ShipmentStatus.Available_For_Pickup:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Delivered icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#38CB89"
            />
          </svg>
          <div className={classes.iconWrapper}>
            <svg
              version="1.2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 454 482"
            >
              <path
                id="Path 0"
                d="m218 72v96h-77.5c-42.6 0-77.5-0.2-77.5-0.5 0-0.3 4.8-10.1 10.6-21.8 5.8-11.6 14.9-29.5 20.2-39.7 6.9-13.1 11-19.8 14.2-23 2.5-2.5 6.5-5.6 9-6.8 2.5-1.2 6.8-2.6 9.5-3.1 2.8-0.5 24.5-0.9 91.5-1.1zm24.1-0.1l46.2 0.3c40.9 0.3 46.7 0.6 50.7 2.1 2.5 1 6.6 3.3 9.2 5.2 2.7 1.9 6.2 5.6 8 8.3 1.8 2.6 11.7 21.4 22 41.7 10.3 20.3 18.8 37.3 18.8 37.8 0 0.4-34.9 0.7-155 0.7zm-180.2 120.1h335.7l-0.1 87.8c-0.1 82.7-0.2 88-2 93.2-1 3.1-3.3 8-5.1 11-1.8 3.1-5 7.3-7.1 9.4-2.1 2.1-6.4 5.3-9.6 7.2-3.1 1.9-8.3 4.2-11.5 5.2-5.3 1.6-14.6 1.7-132.2 1.7-117.6 0-126.9-0.1-132.3-1.7-3.1-1-8.3-3.3-11.5-5.2-3.1-1.9-7.5-5.1-9.6-7.2-2.1-2.1-5.3-6.3-7.1-9.4-1.7-3-4-7.9-5.1-11-1.7-5.1-1.8-10.8-2.2-93.2zm229.6 39.2c-1.6 1.1-21.5 20.6-44.2 43.2l-41.3 41.1c-26.7-26.5-36.1-35.3-38-36.6-2.8-2-4.8-2.4-10-2.4-5.3 0-7.1 0.4-9.9 2.5-1.9 1.4-4.3 3.8-5.5 5.5-1.6 2.3-2.1 4.5-2.1 9.5 0 5.2 0.5 7.2 2.4 10 1.3 1.9 12.6 13.7 25 26.3 12.4 12.6 24.6 24.3 27.1 26 4 2.8 5.3 3.2 11 3.2 5.2 0 7.2-0.5 10-2.4 1.9-1.3 24.6-23.4 50.3-49 25.8-25.7 48.3-48.6 50-51.1 2.8-4 3.2-5.3 3.2-11 0-5.4-0.4-7.1-2.5-9.9-1.4-1.9-3.7-4.3-5.2-5.3-1.5-1-5.1-2-8-2.3-2.9-0.2-6.2-0.2-7.3 0.2-1.1 0.3-3.3 1.4-5 2.5z"
              />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.Return_To_Sender:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Unknown Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#E9ECEF"
            />
          </svg>
          <div className={classes.iconWrapper}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.Unknown:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Unknown Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#E9ECEF"
            />
          </svg>
          <div className={classNames(classes.iconWrapper, classes.preTransit)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.New:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Unknown Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#E9ECEF"
            />
          </svg>
          <div className={classNames(classes.iconWrapper, classes.preTransit)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z" />
            </svg>
          </div>
        </>
      );
      break;

    case ShipmentStatus.StorePickup:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Store Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#E9ECEF"
            />
          </svg>
          <div className={classNames(classes.iconWrapper, classes.preTransit)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M437.7 171.5L385.4 39.6C379.8 25.2 365.9 16 350.1 16H97.9c-15.8 0-29.7 9.2-35.3 23.6L10.3 171.5C3.9 187.7 15.1 208 32.8 208H416.3c17.7 0 28.9-20.3 21.4-36.5zM96 320c-8.8 0-16-7.2-16-16V240H368v64c0 8.8-7.2 16-16 16H96zm336-96H16v160c0 35.3 28.7 64 64 64H368c35.3 0 64-28.7 64-64V224z" />
            </svg>
          </div>
        </>
      );
      break;

    default:
      progress = (
        <>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <desc>Error Icon</desc>
            <circle cx="24" cy="24" r="24" fill="#FAFBFC" />
            <path
              d="M2.09815e-06 24C3.25692e-06 10.7452 10.7452 -3.25692e-06 24 -2.09815e-06C37.2548 -9.39372e-07 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 9.39372e-07 37.2548 2.09815e-06 24ZM46.2386 24C46.2386 11.718 36.282 1.7614 24 1.7614C11.718 1.7614 1.76141 11.718 1.76141 24C1.76141 36.282 11.718 46.2386 24 46.2386C36.282 46.2386 46.2386 36.282 46.2386 24Z"
              fill="#FF0000"
            />
          </svg>
          <div className={classNames(classes.text, classes.errorText)}>
            <span>Error</span>
          </div>
        </>
      );
      break;
  }

  return <div className={classes.main}>{progress}</div>;
};

export default StatusProgress;
