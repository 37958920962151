import usePortalLookAndFeel from "../../../operations/usePortalLookAndFeel";

const Rights = () => {
  const { portalLookAndFeel } = usePortalLookAndFeel();
  const currentYear = new Date().getFullYear();

  return (
    <>
      © {currentYear} {portalLookAndFeel.companyName}. All rights reserved.
    </>
  );
};
export default Rights;
