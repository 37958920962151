import {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Button from "../../Common/Button";
import Paragraph from "../../Common/Paragraph";
import NotificationsActiveIcon from "../../Common/NotificationActiveIcon";
import CloseIcon from "../../Common/CloseIcon";
import classes from "./NotificationsModalForm.module.scss";
import usePortalLookAndFeel from "../../../operations/usePortalLookAndFeel";
import Shipment from "../../../entities/Shipment";
import NotificationsForm from "../../../entities/NotificationsForm";
import NotificationsChannel from "../../../enums/NotificationsChannel";
import ShipmentStatus from "../../../enums/ShipmentStatus";
import usePopulatedShipments from "../../../operations/usePopulatedShipments";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { ApplicationContext } from "../../../providers/ApplicationProvider/index";

export type ShipmentStatusProps = {
  selectedShipment: Shipment;
  setOpenNotificationModalForm: Dispatch<SetStateAction<boolean>>;
};

type FormData = {
  name: string;
  email: string;
  phoneNumber?: string;
  currentStatus: boolean;
  packageReceived: boolean;
  packageInTransit: boolean;
  deliveryFinish: boolean;
  termsConditions: boolean;
  channel: string;
};

const NotificationsModalForm = ({
  selectedShipment,
  setOpenNotificationModalForm,
}: ShipmentStatusProps) => {
  const { addSnack } = useSnackbar();
  const { application } = useContext(ApplicationContext);
  const hasSMS =
    typeof application?.hasSMS === "boolean" ? application?.hasSMS : true;

  const { portalLookAndFeel } = usePortalLookAndFeel();

  const [isLoading, setIsLoading] = useState(false);

  const { createShipmentNotification } = usePopulatedShipments();

  const handleClose = () => {
    setOpenNotificationModalForm(false);
  };

  const orderNumber = selectedShipment.order;

  if (orderNumber !== undefined && orderNumber !== null) {
    const storedOrderNumber = sessionStorage.getItem("orderNumber");
    if (storedOrderNumber !== orderNumber) {
      sessionStorage.clear();
      sessionStorage.setItem("orderNumber", orderNumber);
    }
  }

  const selectedShipmentNotification =
    selectedShipment.shipmentNotifications?.[0];

  const saveFormDataToSessionStorage = (
    formData: FormData,
    shipmentId: string
  ) => {
    const savedFormData = JSON.parse(
      sessionStorage.getItem("formData") || "{}"
    );
    savedFormData[shipmentId] = formData;
    sessionStorage.setItem("formData", JSON.stringify(savedFormData));
  };

  const savedFormData = JSON.parse(sessionStorage.getItem("formData") || "{}");

  const subscribedStatuses = selectedShipmentNotification?.status;

  let newCurrentStatus = false;
  let newPackageReceived = false;
  let newPackageInTransit = false;
  let newDeliveryFinish = false;

  const currentStatusArray = ShipmentStatus.CurrentStatus.split(",");

  if (
    currentStatusArray.every((status) => {
      return subscribedStatuses?.includes(status);
    })
  ) {
    newCurrentStatus = true;
  }

  if (subscribedStatuses?.includes(ShipmentStatus.Delivered)) {
    newPackageReceived = true;
  }

  if (subscribedStatuses?.includes(ShipmentStatus.In_Transit)) {
    newPackageInTransit = true;
  }

  if (subscribedStatuses?.includes(ShipmentStatus.Delivered)) {
    newDeliveryFinish = true;
  }

  const index = selectedShipment.trackingNumber || selectedShipment.id;

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      channel:
        selectedShipmentNotification?.notificationChannel ||
        savedFormData?.[index]?.channel ||
        NotificationsChannel.Email,
      email: selectedShipmentNotification?.email || savedFormData[index]?.email,
      name: selectedShipmentNotification?.name || savedFormData[index]?.name,
      phoneNumber:
        selectedShipmentNotification?.phoneNumber ||
        savedFormData[index]?.phoneNumber,
      currentStatus: newCurrentStatus || savedFormData[index]?.currentStatus,
      packageReceived:
        newPackageReceived || savedFormData[index]?.packageReceived,
      packageInTransit:
        newPackageInTransit || savedFormData[index]?.packageInTransit,
      deliveryFinish: newDeliveryFinish || savedFormData[index]?.deliveryFinish,
    },
  });

  const watchNotificationChannel = watch("channel");
  const watchCurrentStatus = watch("currentStatus");

  useEffect(() => {
    if (watchCurrentStatus) {
      setValue("packageReceived", true);
      setValue("packageInTransit", true);
      setValue("deliveryFinish", true);
    }
  }, [watchCurrentStatus, setValue]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const {
      name,
      email,
      phoneNumber,
      currentStatus,
      packageReceived,
      packageInTransit,
      deliveryFinish,
      channel,
    } = data;

    saveFormDataToSessionStorage(data, index);

    let phone = "";
    if (phoneNumber) {
      const prefix = "+";
      phone = prefix + phoneNumber;
    }

    const shipmentStatuses = [];

    if (currentStatus) {
      shipmentStatuses.push(
        ShipmentStatus.Delivered,
        ShipmentStatus.Pre_Transit,
        ShipmentStatus.In_Transit,
        ShipmentStatus.Out_For_Delivery,
        ShipmentStatus.Delivered,
        ShipmentStatus.Available_For_Pickup,
        ShipmentStatus.Return_To_Sender
      );
    }
    if (packageReceived) {
      shipmentStatuses.push(ShipmentStatus.Delivered);
    }
    if (packageInTransit) {
      shipmentStatuses.push(ShipmentStatus.In_Transit);
    }
    if (deliveryFinish) {
      shipmentStatuses.push(ShipmentStatus.Delivered);
    }

    const notificationsForm = new NotificationsForm({
      channel,
      name,
      email,
      phone,
      shipmentStatuses,
      selectedShipment,
    });

    try {
      setIsLoading(true);
      await createShipmentNotification(notificationsForm);
      addSnack({
        display: true,
        severity: "success",
        message: "Thanks For Subscribing!",
      });

      handleClose();
    } catch (e) {
      console.error(e);
      addSnack({
        display: true,
        severity: "error",
        message: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Manrope",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Modal open onClose={handleClose}>
        <Box className={classes.modalFormBox}>
          <div className={classes.modalFormBoxWrapper}>
            <CloseIcon onClick={handleClose} />
            <div className={classes.notificationMessageIconWrapper}>
              <i className={classes.notificationMessageIcon}>
                <NotificationsActiveIcon />
              </i>
            </div>
            <Typography
              variant="h6"
              component="h2"
              mb={1}
              className={classes.notificationsFormTitle}
            >
              Get status updates about your order
            </Typography>
            {hasSMS && (
              <Paragraph
                size="xs"
                className={classes.notificationsFormSubtitle}
              >
                How would you like to receive the updates?
              </Paragraph>
            )}
            <form
              className={classes.notificationsForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              {hasSMS && (
                <Box className={classes.radioGroup}>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    defaultValue={
                      savedFormData?.channel ||
                      selectedShipmentNotification?.notificationChannel ||
                      NotificationsChannel.Email
                    }
                    {...register("channel", { required: true })}
                    name="channel"
                    render={({ field }: { field: Record<string, unknown> }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value={NotificationsChannel.Email}
                          control={
                            <Radio
                              onChange={() => {
                                clearErrors();
                                setValue("phoneNumber", "");
                              }}
                            />
                          }
                          label="Via Email"
                        />
                        <FormControlLabel
                          value={NotificationsChannel.Sms}
                          control={
                            <Radio
                              onChange={() => {
                                clearErrors();
                                setValue("email", "");
                              }}
                            />
                          }
                          label="Via SMS"
                        />
                      </RadioGroup>
                    )}
                  />
                </Box>
              )}

              <Box className={classes.formGroup}>
                <Controller
                  control={control}
                  defaultValue={"" || selectedShipmentNotification?.name}
                  {...register("name", { required: true })}
                  render={({ field }) => (
                    <InputLabel>
                      Your Name
                      <Input {...field} />
                      <Box className={classes.submitError}>
                        {errors.name && "Your name is required"}
                      </Box>
                    </InputLabel>
                  )}
                />
              </Box>
              {watchNotificationChannel === NotificationsChannel.Email ? (
                <Box className={classes.formGroup}>
                  <Controller
                    control={control}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Your email is required", // TODO Migrate this to yup validation
                      },
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    defaultValue={"" || selectedShipmentNotification?.email}
                    render={({ field }) => (
                      <InputLabel>
                        Email Address
                        <Input type="email" {...field} />
                        <Box className={classes.submitError}>
                          {Object.values(errors).map((e) => {
                            return e.message;
                          })}
                        </Box>
                      </InputLabel>
                    )}
                  />
                </Box>
              ) : (
                <Box className={classes.formGroup}>
                  <Controller
                    control={control}
                    defaultValue={
                      "" || selectedShipmentNotification?.phoneNumber
                    }
                    {...register("phoneNumber", {
                      required: {
                        value: true,
                        message: "Your phone is required",
                      },
                    })}
                    render={({ field }) => (
                      <InputLabel>
                        <span>Phone number</span>
                        <PhoneInput
                          {...field}
                          country="us"
                          enableSearch
                          disableSearchIcon
                          dropdownClass={classes.countryListDropdown}
                          inputClass={classes.phoneNumberInput}
                        />
                        <Box className={classes.submitError}>
                          {Object.values(errors).map((e) => {
                            return e.message;
                          })}
                        </Box>
                      </InputLabel>
                    )}
                  />
                </Box>
              )}
              <Box className={classes.checkboxGroup}>
                <Controller
                  name="currentStatus"
                  defaultValue={savedFormData.currentStatus || newCurrentStatus}
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <InputLabel>
                      <Checkbox {...field} checked={!!value} />
                      Current Status
                    </InputLabel>
                  )}
                />
              </Box>
              <Box
                className={`${classes.checkboxGroup} ${
                  watchCurrentStatus ? classes.checkboxDisabled : ""
                }`}
              >
                <Controller
                  name="packageReceived"
                  defaultValue={
                    savedFormData.packageReceived || newPackageReceived
                  }
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <InputLabel>
                      <Checkbox
                        {...field}
                        checked={!!value}
                        disabled={watchCurrentStatus}
                      />
                      Package Received
                    </InputLabel>
                  )}
                />
              </Box>
              <Box
                className={`${classes.checkboxGroup} ${
                  watchCurrentStatus ? classes.checkboxDisabled : ""
                }`}
              >
                <Controller
                  name="packageInTransit"
                  defaultValue={
                    savedFormData.packageInTransit || newPackageInTransit
                  }
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <InputLabel>
                      <Checkbox
                        {...field}
                        checked={!!value}
                        disabled={watchCurrentStatus}
                      />
                      Package In Transit
                    </InputLabel>
                  )}
                />
              </Box>
              <Box
                className={`${classes.checkboxGroup} ${
                  watchCurrentStatus ? classes.checkboxDisabled : ""
                }`}
              >
                <Controller
                  name="deliveryFinish"
                  defaultValue={
                    savedFormData.deliveryFinish || newDeliveryFinish
                  }
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <InputLabel>
                      <Checkbox
                        {...field}
                        checked={!!value}
                        disabled={watchCurrentStatus}
                      />
                      Delivery has been made
                    </InputLabel>
                  )}
                />
              </Box>
              <Box className={classes.checkboxGroup}>
                <Controller
                  control={control}
                  defaultValue={false}
                  {...register("termsConditions", { required: true })}
                  render={({ field: { value, ...field } }) => (
                    <InputLabel>
                      <Checkbox {...field} checked={!!value} />
                      By clicking submit, you agree our{" "}
                      <a
                        href={portalLookAndFeel.termsAndConditionsLink}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.termsAndConditions}
                      >
                        Terms and conditions
                      </a>
                      <Box className={classes.submitError}>
                        {errors.termsConditions &&
                          "You have to accept our terms and conditions"}
                      </Box>
                    </InputLabel>
                  )}
                />
              </Box>
              <Box className={classes.notificationsFormButtonsWrapper}>
                <div>
                  <Button
                    className={classes.notifcationsCancelButton}
                    kind="secondary"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.notifcationsSubmitButton}
                    kind="primary"
                    type="submit"
                  >
                    Submit
                    {isLoading && (
                      <CircularProgress
                        className={classes.circularProgress}
                        size="1rem"
                        sx={{ color: "white" }}
                      />
                    )}
                  </Button>
                </div>
              </Box>
            </form>
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default NotificationsModalForm;
