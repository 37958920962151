import { useCallback, useContext } from "react";

import Shipment from "../entities/Shipment";
import ShipmentPackage from "../entities/ShipmentPackage";
import ShipmentDetail from "../entities/ShipmentDetail";
import ShipmentNotification from "../entities/ShipmentNotification";
import BaseError from "../errors/BaseError";
import MultipleError from "../errors/MultipleError";
import UnexpectedError from "../errors/UnexpectedError";
import useShipmentDetailsService from "../services/useShipmentDetailsService";
import useShipmentPackageService from "../services/useShipmentPackageService";
import useShipmentNotificationsService from "../services/useShipmentNotificationsService";
import NotificationsForm from "../entities/NotificationsForm";
import useNotificationsFormService from "../services/useNotificationsFormService";
import { PopulatedShipmentsContext } from "../providers/PopulatedShipmentsProvider";

const usePopulatedShipments = () => {
  const { getByShipmentIds: getDetailsByShipmentIds } =
    useShipmentDetailsService();
  const { getByShipmentIds: getPackagesByShipmentIds } =
    useShipmentPackageService();
  const { getByShipmentIds: getNotificationsByShipmentIds } =
    useShipmentNotificationsService();
  const { submitNotificationsForm } = useNotificationsFormService();

  const {
    populatedShipments,
    setPopulatedShipments,
    isLoading,
    setIsLoading,
    error,
    setError,
    showNotificationsButton,
    setShowNotificationsButton,
  } = useContext(PopulatedShipmentsContext);

  const load = useCallback(
    async (shipments: Shipment[]) => {
      try {
        setIsLoading(true);
        const shipmentNumbers = shipments?.map((shipment) => shipment.id);

        const [
          detailsResults,
          packagesContentResults,
          shipmentNotificationsResults,
        ] = await Promise.allSettled([
          getDetailsByShipmentIds(shipmentNumbers),
          getPackagesByShipmentIds(shipmentNumbers),
          getNotificationsByShipmentIds(shipmentNumbers),
        ]);

        let details: ShipmentDetail[] | undefined;
        const errors = [];

        if (detailsResults.status === "fulfilled") {
          details = detailsResults.value.results;
        } else {
          errors.push(
            new UnexpectedError(
              "Shipment details couldn't be loaded",
              "Failed to get the shipment details, try again later.",
              detailsResults.reason
            )
          );
        }

        let packagesContent: ShipmentPackage[] | undefined;

        if (packagesContentResults.status === "fulfilled") {
          packagesContent = packagesContentResults.value.results;
        } else {
          errors.push(
            new UnexpectedError(
              "Shipment packages couldn't be loaded",
              "Failed to get the shipment packages, try again later.",
              packagesContentResults.reason
            )
          );
        }

        let shipmentNotificationsContent: ShipmentNotification[] | undefined;

        if (shipmentNotificationsResults.status === "fulfilled") {
          setShowNotificationsButton(true);
          shipmentNotificationsContent =
            shipmentNotificationsResults.value.results;
        } else {
          errors.push(
            new UnexpectedError(
              "Shipment notifications couldn't be loaded",
              "Failed to get the shipment notifications, try again later.",
              shipmentNotificationsResults.reason
            )
          );
        }

        const newPopulatedShipments = shipments?.map((shipment) => {
          const newShipment = new Shipment({ ...shipment });
          newShipment.shipmentDetailList = details?.filter(
            (detail) => detail.shipmentId === newShipment.id
          );
          newShipment.shipmentPackages = packagesContent?.filter(
            (packageContent) => packageContent.shipmentId === newShipment.id
          );
          newShipment.shipmentNotifications =
            shipmentNotificationsContent?.filter(
              (shipmentNotificationContent) =>
                shipmentNotificationContent.shipment === newShipment.id
            );

          return newShipment;
        });
        if (errors.length) {
          throw new MultipleError<Shipment[]>(
            errors,
            "Shipment details couldn't be loaded",
            "An unexpected error occurred while loading shipment statuses and packages",
            newPopulatedShipments
          );
        }

        setPopulatedShipments(newPopulatedShipments);
      } catch (e) {
        if (e instanceof BaseError && e.partialResult) {
          setPopulatedShipments(e.partialResult);
        }
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      getDetailsByShipmentIds,
      getPackagesByShipmentIds,
      getNotificationsByShipmentIds,
      setPopulatedShipments,
      setError,
      setShowNotificationsButton,
    ]
  );
  const createShipmentNotification = useCallback(
    async (notificationsForm: NotificationsForm) => {
      const newNotification = await submitNotificationsForm(notificationsForm);
      setPopulatedShipments((prevPopulatedShipments) => {
        return prevPopulatedShipments?.map((shipment) => {
          if (shipment.id === notificationsForm.selectedShipment.id) {
            return new Shipment({
              ...shipment,
              shipmentNotifications: [newNotification],
            });
          }
          return shipment;
        });
      });
    },
    [setPopulatedShipments, submitNotificationsForm]
  );

  return {
    populatedShipments,
    isLoading,
    error,
    load,
    createShipmentNotification,
    setShowNotificationsButton,
    showNotificationsButton,
  };
};

export default usePopulatedShipments;
