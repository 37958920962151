import { useCallback, useContext, useState } from "react";
import { isEqual } from "lodash";

import PortalLookAndFeel, {
  PortalLookAndFeelRecord,
} from "../entities/PortalLookAndFeel";
import AuthenticationError from "../errors/AuthenticationError";
import BaseError from "../errors/BaseError";
import { PortalLookAndFeelContext } from "../providers/PortalLookAndFeelProvider";
import usePortalLookAndFeelService from "../services/usePortalLookAndFeelService";
import useStorageService from "../services/useStorageService";
import { getHostname } from "../utils/location-utils";
import useAuth from "./useAuth";
import { usePortalLookAndFeelStorage } from "../hooks/usePortalLookAndFeelStorage";

export const PORTAL_LOOK_AND_FEEL_STORAGE_NAME = "portal-look-and-feels.json";

const usePortalLookAndFeel = () => {
  const { application } = useAuth();
  const { fetch } = useStorageService();
  const { getById } = usePortalLookAndFeelService();
  const { setPortalLookAndFeelFromStorage, portalLookAndFeelFromLocalStorage } =
    usePortalLookAndFeelStorage();

  const { portalLookAndFeel, setPortalLookAndFeel } = useContext(
    PortalLookAndFeelContext
  );

  const [error, setError] = useState<{ title: string; message: string }>();

  const loadFromCache = useCallback(
    async (isLoading, setIsLoading) => {
      try {
        const portalLookAndFeelFromStorage =
          await fetch<PortalLookAndFeelRecord>(
            `/web-cache/unlockshippings/${getHostname()}/${PORTAL_LOOK_AND_FEEL_STORAGE_NAME}`
          );

        const portalLookAndFeelFromCacheStorage =
          PortalLookAndFeel.parsePortalLookAndFeelRecord(
            portalLookAndFeelFromStorage
          );

        if (
          !isEqual(
            portalLookAndFeelFromCacheStorage,
            portalLookAndFeelFromLocalStorage
          )
        ) {
          setPortalLookAndFeelFromStorage(portalLookAndFeelFromCacheStorage);
          setPortalLookAndFeel(portalLookAndFeelFromCacheStorage);
        }
      } finally {
        if (isLoading) {
          setIsLoading(false);
        }
      }
    },
    [
      fetch,
      setPortalLookAndFeel,
      setPortalLookAndFeelFromStorage,
      portalLookAndFeelFromLocalStorage,
    ]
  );

  const load = useCallback(
    async (isLoading, setIsLoading) => {
      try {
        if (!application || !application.lookAndFeelId) {
          throw new AuthenticationError(
            "Missing application information",
            "It seems like authentication wasn't done. Please authenticate to retrieve information."
          );
        }

        const portalLookAndFeelFromNetSuite = await getById(
          application.lookAndFeelId
        );

        if (
          !isEqual(
            portalLookAndFeelFromNetSuite,
            portalLookAndFeelFromLocalStorage
          )
        ) {
          setPortalLookAndFeelFromStorage(portalLookAndFeelFromNetSuite);

          setError(undefined);
          setPortalLookAndFeel(portalLookAndFeelFromNetSuite);
        }
      } catch (e) {
        setError(e as BaseError<undefined>);
      } finally {
        if (isLoading) {
          setIsLoading(false);
        }
      }
    },
    [
      application,
      getById,
      setPortalLookAndFeel,
      setPortalLookAndFeelFromStorage,
      portalLookAndFeelFromLocalStorage,
    ]
  );

  return {
    portalLookAndFeel,
    loadFromCache,
    load,
    error,
  };
};

export default usePortalLookAndFeel;
