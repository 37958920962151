import Entity, { EntityOptions } from "./Entity";

export type CredentialOptions = EntityOptions & { token: string };

export default class Credential extends Entity<CredentialOptions> {
  token?: string;

  constructor(options: CredentialOptions) {
    super(options);
    this.set(options);
  }
}
