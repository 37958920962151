import classNames from "classnames";
import classes from "./ExternalLink.module.scss";

export type ExternalLinkKind = "primary" | "secondary";

export type ExternalLinkProps = {
  children: React.ReactNode;
  className?: string;
  to: string | undefined;
  underlined?: boolean;
  bold?: boolean;
  kind?: ExternalLinkKind;
  target?: string;
};

const ExternalLink = ({
  to,
  className = "",
  children,
  underlined = false,
  bold = false,
  kind = "primary",
  target = "",
}: ExternalLinkProps) => {
  return (
    <a
      className={classNames(classes.link, classes[kind], className, {
        [classes.underlined]: underlined,
        [classes.bold]: bold,
      })}
      href={to}
      target={target}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
