import { useCallback } from "react";

import ShipmentDetail from "../entities/ShipmentDetail";
import useNetSuiteService from "./useNetSuiteService";

export type ShipmentDetailsRecord = {
  id: string;
  custrecord_ucp_shipment_parent: string;
  custrecord_ucp_shipment_detail_msg: string;
  custrecord_ucp_shipment_detail_stat: string;
  custrecord_ucp_shipment_detail_time: string;
  custrecord_ucp_shipment_detail_loc: string;
};

const useShipmentDetailsService = () => {
  const { getFromNetSuite } = useNetSuiteService<ShipmentDetailsRecord>({
    resource: "customrecord_ucp_shipment_details",
  });

  const getByShipmentIds = useCallback(
    async (shipmentIds) => {
      const body = await getFromNetSuite({
        filters: [["custrecord_ucp_shipment_parent", "anyof", ...shipmentIds]],
        columns: [
          "custrecord_ucp_shipment_parent",
          "custrecord_ucp_shipment_detail_msg",
          "custrecord_ucp_shipment_detail_stat",
          "custrecord_ucp_shipment_detail_time",
          "custrecord_ucp_shipment_detail_loc",
        ],
        sort: {
          field: "custrecord_ucp_shipment_detail_time",
          order: "DESC",
        },
      });

      const results = body.results.map(
        (detail) =>
          new ShipmentDetail({
            id: detail.id,
            shipmentId: detail.custrecord_ucp_shipment_parent,
            message: detail.custrecord_ucp_shipment_detail_msg,
            status: detail.custrecord_ucp_shipment_detail_stat,
            date: detail.custrecord_ucp_shipment_detail_time,
            location: detail.custrecord_ucp_shipment_detail_loc,
          })
      );

      return { ...body, results };
    },
    [getFromNetSuite]
  );

  return { getByShipmentIds };
};

export default useShipmentDetailsService;
