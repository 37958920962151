/* eslint-disable @typescript-eslint/no-explicit-any */
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadIcon from "../../Common/DownloadIcon";
import ShipmentStatusPdf from "../ShipmentStatusPdf";
import Shipment from "../../../entities/Shipment";
import usePortalLookAndFeel from "../../../operations/usePortalLookAndFeel";

const MyPDFDownloadLink: any = PDFDownloadLink;

export type ShipmentTimelineProps = {
  selectedShipment: Shipment;
};

const ShipmentStatusDownloadButton = ({
  selectedShipment,
}: ShipmentTimelineProps) => {
  const { portalLookAndFeel } = usePortalLookAndFeel();
  const isLoading = selectedShipment?.shipmentNotifications === undefined;
  const { carrierIsNotSupported } = selectedShipment;
  if (isLoading || carrierIsNotSupported) return null;

  return (
    <div>
      <MyPDFDownloadLink
        document={
          <ShipmentStatusPdf
            selectedShipment={selectedShipment}
            portalLookAndFeel={portalLookAndFeel}
          />
        }
        fileName={`${portalLookAndFeel.companyName?.split(" ").join("")}-${
          selectedShipment.trackingNumber
        }-report.pdf`}
      >
        <DownloadIcon />
      </MyPDFDownloadLink>
    </div>
  );
};

export default ShipmentStatusDownloadButton;
