import { useCallback, useContext, useState } from "react";
import { useSnackbar } from "../hooks/useSnackbar";

import Application from "../entities/Application";
import Credential from "../entities/Credential";
import AuthenticationError from "../errors/AuthenticationError";
import BaseError from "../errors/BaseError";
import UnexpectedError from "../errors/UnexpectedError";
import { ApplicationContext } from "../providers/ApplicationProvider";
import { CredentialContext } from "../providers/CredentialProvider";
import { ShipmentsContext } from "../providers/ShipmentsProvider";
import useAuthService from "../services/useAuthService";
import { getHostname } from "../utils/location-utils";
import { usePortalLookAndFeelStorage } from "../hooks/usePortalLookAndFeelStorage";

const useAuth = () => {
  const { authenticate } = useAuthService();

  const { credential, setCredential } = useContext(CredentialContext);
  const { application, setApplication } = useContext(ApplicationContext);
  const {
    shipments,
    setShipments,
    error: shipmentsError,
    setError: setShipmentsError,
    isLoading: isLoadingShipments,
    setIsLoading: setIsLoadingShipments,
  } = useContext(ShipmentsContext);

  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const { hasPortalLookAndFeelFromLocalStorage } =
    usePortalLookAndFeelStorage();

  const authenticateWithoutCredentials = useCallback(async () => {
    try {
      setError(null);
      if (!hasPortalLookAndFeelFromLocalStorage) {
        setIsLoading(true);
      }
      const appResponse = await authenticate(getHostname());

      const applicationData = appResponse?.application;
      const token = appResponse?.token;

      if (!token) {
        throw new AuthenticationError();
      }

      setCredential(new Credential({ token }));
      setApplication(new Application(applicationData));
    } catch (e) {
      const newError = new UnexpectedError(
        "Unexpected error",
        "An unexpected error occurred, please check the url or try again later",
        e as Error
      );
      const err = e as Error;
      setError(err ?? newError);
    } finally {
      setIsLoading(false);
    }
  }, [
    authenticate,
    setApplication,
    setCredential,
    setError,
    setIsLoading,
    hasPortalLookAndFeelFromLocalStorage,
  ]);

  const authenticateWithCredentials = useCallback(
    async (orderNumber?: string, zipCode?: string, email?: string) => {
      try {
        setError(null);
        setShipmentsError();
        setIsLoading(true);
        setIsLoadingShipments(true);

        const {
          application: applicationData,
          token,
          shipments: matchingShipments,
        } = await authenticate(getHostname(), orderNumber, zipCode, email);

        if (!token) {
          throw new AuthenticationError();
        }

        setCredential(new Credential({ token }));
        setApplication(new Application(applicationData));
        setShipments(matchingShipments);
        return true;
      } catch (e) {
        const { title, message } = e as BaseError<undefined>;
        const newError = new UnexpectedError(
          title || "Unexpected Error",
          message,
          e as Error
        );
        setError(newError);
        setShipmentsError(newError);
        addSnack({
          display: true,
          severity: "error",
          message: newError.message,
        });
        return false;
      } finally {
        setIsLoading(false);
        setIsLoadingShipments(false);
      }
    },
    [
      setShipmentsError,
      authenticate,
      setCredential,
      setApplication,
      setShipments,
      setIsLoadingShipments,
      addSnack,
    ]
  );

  const reset = useCallback(() => {
    setShipments();
    setShipmentsError();
  }, [setShipments, setShipmentsError]);

  return {
    credential,
    application,
    isLoading,
    error,
    authenticateWithoutCredentials,
    authenticateWithCredentials,
    shipments,
    shipmentsError,
    isLoadingShipments,
    reset,
  };
};

export default useAuth;
