import { useMediaQuery } from "react-responsive";
import { ReactNode, useMemo } from "react";
import dayjs from "dayjs";

import Shipment from "../../../entities/Shipment";
import Title from "../../Common/Title";
import classes from "./ShipmentDetails.module.scss";
import ShipmentStatusDownloadButton from "../ShipmentStatusDownloadButton";

export type ShipmentDetailsProps = {
  selectedShipment: Shipment;
};

const ShipmentDetails = ({ selectedShipment }: ShipmentDetailsProps) => {
  const {
    carrier,
    trackingNumber,
    expectedDelivery,
    shipAddress,
    shipmentName,
    status,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = selectedShipment!;
  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });

  const isStorePickup = status === "12";

  const detailsColumns: { first: ReactNode; second: ReactNode } =
    useMemo(() => {
      const columns = [
        { label: "Carrier:", value: carrier },
        {
          label: isStorePickup ? "Pickup Number" : "Tracking number:",
          value: trackingNumber,
        },
        {
          label: "Expected delivery:",
          value: expectedDelivery
            ? dayjs(expectedDelivery).format("MMM DD, YYYY").toString()
            : undefined,
        },
        { label: "Shipping to:", value: shipmentName },
        { label: "Shipping address:", value: shipAddress },
      ];

      if (isStorePickup) {
        columns.splice(-2);
      }

      const noEmptyColumns = columns.filter((detail) => detail.value);

      const elementsColumn = noEmptyColumns.map((detail) => (
        <div key={detail.value} className={classes.valueContainer}>
          <span className={classes.label}>{detail.label}</span>
          <span className={classes.value}>{detail.value}</span>
        </div>
      ));

      const half = Math.ceil(elementsColumn.length / 2);
      const firstHalf = elementsColumn.slice(0, half);
      const secondHalf = elementsColumn.slice(half, elementsColumn.length);

      const firstHalfColumns = <>{firstHalf.map((column) => column)}</>;
      const secondHalfColumns = <>{secondHalf.map((column) => column)}</>;
      return { first: firstHalfColumns, second: secondHalfColumns };
    }, [
      carrier,
      trackingNumber,
      expectedDelivery,
      shipmentName,
      shipAddress,
      isStorePickup,
    ]);

  return (
    <>
      <div className={classes.titleWrapper}>
        <Title size="m" kind="h3">
          Package Details&nbsp;
        </Title>
        <ShipmentStatusDownloadButton selectedShipment={selectedShipment} />
      </div>
      <div className={classes.info}>
        {!isMobile ? (
          <>
            <div className={classes.column}>{detailsColumns.first}</div>
            <div className={classes.column}>{detailsColumns.second}</div>
          </>
        ) : (
          <>
            {detailsColumns.first}
            {detailsColumns.second}
          </>
        )}
      </div>
    </>
  );
};

export default ShipmentDetails;
