import { FC } from "react";
import classNames from "classnames";
import classes from "./LayoutContainer.module.scss";

type LayoutContainerType = {
  children: React.ReactNode;
  className?: string;
};

const LayoutContainer: FC<LayoutContainerType> = ({ children, className }) => {
  return (
    <div className={classNames(classes.container, `${className ?? ""}`)}>
      {children}
    </div>
  );
};

export default LayoutContainer;
