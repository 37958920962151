import { Dispatch, FC, SetStateAction } from "react";

import classes from "./styles.module.scss";

import NotificationMessageIcon from "../../Common/NotificationMessageIcon";
import Button from "../../Common/Button";

import Shipment from "../../../entities/Shipment";

type NotificationButtonType = {
  selectedShipment: Shipment;
  setOpenNotificationModalForm: Dispatch<SetStateAction<boolean>>;
};

export const NotificationButton: FC<NotificationButtonType> = ({
  selectedShipment,
  setOpenNotificationModalForm,
}) => {
  const handleOpen = () => setOpenNotificationModalForm(true);

  const isLoading = selectedShipment?.shipmentNotifications === undefined;

  const { carrierIsNotSupported } = selectedShipment;

  if (isLoading || carrierIsNotSupported) return null;

  return (
    <Button className={classes.getNotificationsButton} onClick={handleOpen}>
      <NotificationMessageIcon />
      Get Notifications
    </Button>
  );
};
