import classes from "./BurgerIcon.module.scss";

const BurgerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={classes.burger}
      xmlns="http://www.w3.org/2000/svg"
      aria-label="menu link"
      role="img"
    >
      <desc>Menu Link</desc>
      <path
        d="M3 7H21"
        strokeWidth="1.5"
        strokeLinecap="round"
        className={classes.burger}
      />
      <path
        d="M3 12H21"
        strokeWidth="1.5"
        strokeLinecap="round"
        className={classes.burger}
      />
      <path
        d="M3 17H21"
        strokeWidth="1.5"
        strokeLinecap="round"
        className={classes.burger}
      />
    </svg>
  );
};

export default BurgerIcon;
