import * as React from "react";
import { useMemo } from "react";
import { Popover, Typography } from "@mui/material";
import ShipmentPackage from "../../../entities/ShipmentPackage";
import classes from "./ShipmentPackageComponent.module.scss";
import noImage from "../../../assets/images/no-image-available.jpeg";

export type ShipmentPackageProps = {
  shipmentPackage?: ShipmentPackage;
};

const ShipmentPackageComponent = ({
  shipmentPackage,
}: ShipmentPackageProps) => {
  const imageUrl = useMemo(
    () => shipmentPackage?.imageUrl || noImage,
    [shipmentPackage?.imageUrl]
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={imageUrl} alt="Package img" className={classes.image} />
      </div>
      <div className={classes.info}>
        <span className={classes.name}>{shipmentPackage?.item}</span>
        <span
          aria-owns={open ? "package-item-description-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          className={classes.description}
        >
          {shipmentPackage?.description}
        </span>
        <Popover
          id="package-item-description-popover"
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            className={classes.descriptionPopover}
            sx={{ p: 1, fontSize: "11px", maxWidth: "400px" }}
          >
            {shipmentPackage?.description}
          </Typography>
        </Popover>
        <div className={classes.quantity}>
          Quantity:{" "}
          <span className={classes.value}>{shipmentPackage?.quantity}</span>
        </div>
      </div>
    </div>
  );
};

export default ShipmentPackageComponent;
