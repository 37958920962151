import { FC } from "react";

import classNames from "classnames";
import classes from "./Row.module.scss";

type RowType = {
  children: React.ReactNode;
  className?: string;
};

const Row: FC<RowType> = ({ children, className }) => {
  return <div className={classNames(classes.row, className)}>{children}</div>;
};

export default Row;
