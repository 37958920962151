import BaseError from "./BaseError";

export default class AuthenticationError extends BaseError<undefined> {
  constructor(
    title = "Authentication Error",
    message = "Authentication could not be performed, please try again later",
    cause?: Error
  ) {
    super(title, message, cause);
  }
}
