import Entity, { EntityOptions } from "./Entity";
import ShipmentPackage from "./ShipmentPackage";
import ShipmentDetail from "./ShipmentDetail";
import ShipmentNotification from "./ShipmentNotification";
import ShipmentStatus from "../enums/ShipmentStatus";

export type ShipmentOptions = EntityOptions & {
  trackingNumber?: string;
  zipCode?: string;
  order?: string;
  status?: ShipmentStatus;
  carrier?: string;
  sms?: string;
  email?: string;
  latestEvent?: string;
  shipAddress?: string;
  shipmentName?: string;
  shipmentDetailList?: ShipmentDetail[];
  shipmentPackages?: ShipmentPackage[];
  shipmentNotifications?: ShipmentNotification[];
  expectedDelivery?: string;
  publicUrl?: string;
  carrierIsNotSupported?: boolean;
};

export default class Shipment extends Entity<ShipmentOptions> {
  trackingNumber?: string;

  zipCode?: string;

  order?: string;

  status?: ShipmentStatus;

  carrier?: string;

  sms?: string;

  email?: string;

  latestEvent?: string;

  shipAddress?: string;

  shipmentName?: string;

  shipmentDetailList?: ShipmentDetail[];

  shipmentPackages?: ShipmentPackage[];

  shipmentNotifications?: ShipmentNotification[];

  expectedDelivery?: string;

  publicUrl?: string;

  carrierIsNotSupported?: boolean;

  constructor(options: ShipmentOptions) {
    super(options);
    this.set(options);
  }
}
