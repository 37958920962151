import { ReactNode } from "react";
import ApplicationProvider from "../ApplicationProvider";
import CredentialProvider from "../CredentialProvider";
import PopulatedShipmentsProvider from "../PopulatedShipmentsProvider";
import PortalLookAndFeelProvider from "../PortalLookAndFeelProvider";
import ShipmentsProvider from "../ShipmentsProvider";

const RootProvider = ({ children }: { children: ReactNode }) => (
  <CredentialProvider>
    <ApplicationProvider>
      <PortalLookAndFeelProvider>
        <ShipmentsProvider>
          <PopulatedShipmentsProvider>{children}</PopulatedShipmentsProvider>
        </ShipmentsProvider>
      </PortalLookAndFeelProvider>
    </ApplicationProvider>
  </CredentialProvider>
);

export default RootProvider;
