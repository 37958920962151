import Button from "../../components/Common/Button";
import FooterContainer from "../../components/Common/FooterContainer";
import Header from "../../components/Common/Header";
import Link from "../../components/Common/Link";
import NotFoundIcon from "../../components/Common/NotFoundIcon";
import Title from "../../components/Common/Title";
import Path from "../../enums/Path";
import classes from "./NotFound.module.scss";

const NotFound = () => (
  <div className={classes.notFoundPage}>
    <Header />
    <div className={classes.container}>
      <div className={classes.box}>
        <NotFoundIcon />
        <Title size="l">Page not found</Title>
        <span className={classes.message}>
          This page doesn’t exist or was removed. We suggest you back to home
        </span>
        <Link to={Path.Home} underlined className={classes.backButton}>
          <Button kind="primary">Go back to home page</Button>
        </Link>
      </div>
    </div>
    <FooterContainer />
  </div>
);

export default NotFound;
