import { BrowserRouter, Routes, Route } from "react-router-dom";

import Path from "../../enums/Path";
import Home from "../Home";
import OrderPage from "../OrderPage";
import NotFound from "../NotFound";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Home />} path={Path.Home} />
      <Route element={<OrderPage />} path={Path.Order} />
      <Route element={<NotFound />} path="*" />
    </Routes>
  </BrowserRouter>
);

export default Router;
