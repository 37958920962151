import { useEffect, useMemo, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Select, { StylesConfig } from "react-select";

import Header from "../../components/Common/Header";
import LoadSpinner from "../../components/Common/LoadSpinner";
import ShipmentComponent from "../../components/Shipment/ShipmentComponent";
import Shipment from "../../entities/Shipment";
import Path from "../../enums/Path";
import classes from "./OrderPage.module.scss";
import Dropdown from "../../components/Common/Dropdown";
import Title from "../../components/Common/Title";
import ShipmentDetails from "../../components/Shipment/ShipmentDetails";
import Paragraph from "../../components/Common/Paragraph";
import ShipmentStatusComponent from "../../components/Shipment/ShipmentStatus";
import ShipmentPackageComponent from "../../components/Shipment/ShipmentPackageComponent";
import FooterContainer from "../../components/Common/FooterContainer";
import ExternalLink from "../../components/Common/ExternalLink";
import TopSmallBanner from "../../components/Common/TopSmallBanner";
import useAuth from "../../operations/useAuth";
import usePortalLookAndFeel from "../../operations/usePortalLookAndFeel";
import usePopulatedShipments from "../../operations/usePopulatedShipments";
import NotificationsModalForm from "../../components/Notifications/NotificationsModalForm";
import { NotificationButton } from "../../components/Notifications/NotificationsButton";
import LayoutContainer from "../../components/Common/LayoutContainer";
import Column from "../../components/Common/Column";
import Row from "../../components/Common/Row";

const OrderPage = () => {
  const { portalLookAndFeel } = usePortalLookAndFeel();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    shipments: authShipments,
    authenticateWithCredentials,
    isLoadingShipments,
    shipmentsError,
  } = useAuth();
  const {
    populatedShipments,
    showNotificationsButton,
    error: populatedShipmentsError,
    load: loadPopulatedShipments,
  } = usePopulatedShipments();

  const isMobile = useMediaQuery({
    query: `(max-width: ${classes.screenXl})`,
  });
  const [selectedShipment, setSelectedShipment] = useState<Shipment>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenAlert, setIsOpenAlert] = useState(true);
  const [openNotificationModalForm, setOpenNotificationModalForm] =
    useState(false);

  const shipments = useMemo(
    () => populatedShipments || authShipments,
    [authShipments, populatedShipments]
  );

  const orderNumber = useMemo(
    () => searchParams.get("order-number") ?? undefined,
    [searchParams]
  );

  const zipCode = useMemo(
    () => searchParams.get("zip-code") ?? undefined,
    [searchParams]
  );

  const email = useMemo(
    () => searchParams.get("email") ?? undefined,
    [searchParams]
  );

  const tracking = useMemo(
    () => searchParams.get("tracking") ?? undefined,
    [searchParams]
  );

  useEffect(() => {
    authenticateWithCredentials(orderNumber, zipCode, email);
  }, [authenticateWithCredentials, orderNumber, zipCode, email]);

  useEffect(() => {
    setSelectedShipment(() => {
      return shipments?.find(
        (shipment) => shipment.trackingNumber === tracking
      );
    });
  }, [tracking, shipments]);

  useEffect(() => {
    if (authShipments) {
      loadPopulatedShipments(authShipments);
    }
  }, [authShipments, loadPopulatedShipments]);

  useEffect(() => {
    if (shipments?.length) {
      setSelectedShipment((prevSelectedShipment) => {
        if (!prevSelectedShipment) {
          return shipments[0];
        }
        return shipments.find(
          (shipment) => shipment.id === prevSelectedShipment.id
        );
      });
    }
  }, [shipments]);

  const onClickShipment = (newSelectedShipment: Shipment) => {
    setSelectedShipment(newSelectedShipment);
    setIsOpenDropdown(false);
    searchParams.set("tracking", newSelectedShipment.trackingNumber as string);
    setSearchParams(searchParams);
  };

  const onClickDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const onChangeShipment = (newShipment: unknown) => {
    const { value: shipment } = newShipment as {
      value: Shipment;
      label: unknown;
    };
    setSelectedShipment(shipment);
    searchParams.set("tracking", shipment.trackingNumber as string);
    setSearchParams(searchParams);
  };

  const totalOfShipments =
    shipments?.filter(
      (elem, index, self) => index === self.findIndex((t) => t.id === elem.id)
    ).length ?? "";

  const options = useMemo(() => {
    return shipments
      ?.filter(
        (elem, index, self) => index === self.findIndex((t) => t.id === elem.id)
      )
      ?.map((shipment, index) => ({
        value: shipment,
        label: (
          <div className={classes.selectOption}>
            <span className={classes.selectNumber}>
              Package {index + 1}/{totalOfShipments}&nbsp;&#8211;&nbsp;
            </span>
            <span className={classes.selectCarrier}>
              {shipment.carrier}&nbsp;&#8211;&nbsp;
            </span>
            <span className={classes.selectTracking}>
              Tracking N°&nbsp;
              <span className={classes.value}>{shipment.trackingNumber}</span>
            </span>
          </div>
        ),
      }));
  }, [shipments, totalOfShipments]);

  const colorStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      fontSize: classes.fontSizeXxs,
      borderColor: classes.grayLines,
    }),
    option: (styles, { data }) => {
      const { value: shipment } = data as { value: Shipment };

      return {
        ...styles,
        fontSize: classes.fontSizeXxs,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor:
          shipment.id === selectedShipment?.id
            ? classes.grayLines
            : classes.white,
      };
    },
  };

  const isNotificationModalVisible =
    openNotificationModalForm &&
    showNotificationsButton &&
    selectedShipment &&
    !selectedShipment?.carrierIsNotSupported;

  return (
    <>
      {isNotificationModalVisible && (
        <NotificationsModalForm
          selectedShipment={selectedShipment}
          setOpenNotificationModalForm={setOpenNotificationModalForm}
        />
      )}
      {populatedShipmentsError && (
        <Alert severity="error">{populatedShipmentsError.message}</Alert>
      )}
      {isLoadingShipments && <LoadSpinner />}
      {shipmentsError && <Navigate to={Path.Home} />}
      {shipments && (
        <>
          <Header />
          {portalLookAndFeel.topBannerText && isOpenAlert && (
            <TopSmallBanner onClick={() => setIsOpenAlert(false)} />
          )}
          {!isMobile ? (
            <>
              <LayoutContainer className={classes.mainContainer}>
                <Row>
                  <Column width={50} className={classes.colLeft}>
                    <div className={classes.packages}>
                      <Title
                        kind="h1"
                        size="l"
                        style={{ color: portalLookAndFeel.primaryColor }}
                      >
                        Order #{orderNumber}
                      </Title>
                      {portalLookAndFeel.yourAccount && (
                        <span className={classes.yourAccount}>
                          View all your order details in{" "}
                          <ExternalLink
                            to={portalLookAndFeel.yourAccount}
                            underlined
                          >
                            your account
                          </ExternalLink>
                        </span>
                      )}
                      <div className={classes.yourPackages}>
                        <Title size="m" kind="h2">
                          Your Packages
                        </Title>
                        {shipments
                          ?.filter(
                            (elem, index, self) =>
                              index === self.findIndex((t) => t.id === elem.id)
                          )
                          .map((individualShipment) => (
                            <ShipmentComponent
                              shipment={individualShipment}
                              selected={
                                selectedShipment?.id === individualShipment.id
                              }
                              key={individualShipment.id}
                              onClick={onClickShipment}
                            />
                          ))}
                      </div>
                      {portalLookAndFeel.imageBanner && (
                        <div className={classes.imageBanner}>
                          <ExternalLink
                            to={portalLookAndFeel.imageBannerLink}
                            target="_blank"
                          >
                            <img
                              src={portalLookAndFeel.imageBanner}
                              alt={`${portalLookAndFeel.companyName} banner`}
                            />
                          </ExternalLink>
                        </div>
                      )}
                      {portalLookAndFeel.homeBannerHtml && (
                        <div
                          className={classes.homeBanner}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: portalLookAndFeel.homeBannerHtml,
                          }}
                        />
                      )}
                    </div>
                  </Column>
                  <Column width={50} className={classes.colRight}>
                    {selectedShipment ? (
                      <div className={classes.details}>
                        <ShipmentDetails selectedShipment={selectedShipment} />
                        {selectedShipment.status !== "12" && (
                          <ShipmentStatusComponent
                            selectedShipment={selectedShipment}
                            setOpenNotificationModalForm={
                              setOpenNotificationModalForm
                            }
                          />
                        )}
                        <Dropdown
                          titleContainer={
                            <div>
                              <Title
                                size="m"
                                kind="h3"
                                className={classes.dropdownTitle}
                              >
                                Shipped Items
                              </Title>
                              {selectedShipment.shipmentPackages ? (
                                <Paragraph
                                  size="s"
                                  className={classes.dropdownSubTitle}
                                >
                                  <span> Shipped in this package: </span>
                                  {
                                    selectedShipment.shipmentPackages?.length
                                  }{" "}
                                  items
                                </Paragraph>
                              ) : (
                                <Paragraph
                                  size="s"
                                  className={classes.dropdownSubTitle}
                                >
                                  Loading...
                                </Paragraph>
                              )}
                            </div>
                          }
                          disabled={
                            selectedShipment.shipmentPackages === undefined ||
                            selectedShipment.shipmentPackages?.length === 0
                          }
                          onClick={onClickDropdown}
                          isOpen={isOpenDropdown}
                        >
                          <div className={classes.itemsContainer}>
                            <div className={classes.items}>
                              {selectedShipment.shipmentPackages?.map(
                                (shipmentPackage) => {
                                  return (
                                    <div
                                      key={shipmentPackage.id}
                                      className={classes.item}
                                    >
                                      <ShipmentPackageComponent
                                        shipmentPackage={shipmentPackage}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    ) : (
                      <div className={classes.details}>
                        Please select a package
                      </div>
                    )}
                  </Column>
                </Row>
              </LayoutContainer>
              <FooterContainer />
            </>
          ) : (
            <>
              {portalLookAndFeel.mobileImageBanner && (
                <div className={classes.imageBanner}>
                  <ExternalLink to={portalLookAndFeel.mobileImageBannerLink}>
                    <img
                      src={portalLookAndFeel.mobileImageBanner}
                      alt={`${portalLookAndFeel.companyName} banner`}
                    />
                  </ExternalLink>
                </div>
              )}
              <div className={classes.container}>
                <div className={classes.packages}>
                  <Title
                    kind="h1"
                    size="l"
                    style={{ color: portalLookAndFeel.primaryColor }}
                  >
                    Order #{orderNumber}
                  </Title>
                  {portalLookAndFeel.yourAccount && (
                    <span className={classes.yourAccount}>
                      View all your order details in{" "}
                      <ExternalLink
                        to={portalLookAndFeel.yourAccount}
                        underlined
                      >
                        your account
                      </ExternalLink>
                    </span>
                  )}
                  <div className={classes.yourPackages}>
                    <div className={classes.yourPackagesTitleWrapper}>
                      <p className={classes.yourPackagesTitle}>Your Package</p>
                      {selectedShipment && (
                        <NotificationButton
                          selectedShipment={selectedShipment}
                          setOpenNotificationModalForm={
                            setOpenNotificationModalForm
                          }
                        />
                      )}
                    </div>
                    <Select
                      styles={colorStyles}
                      options={options}
                      onChange={onChangeShipment}
                      isSearchable={false}
                      id="select_order_page"
                      name="select_order_page"
                      defaultValue={
                        tracking
                          ? options?.find(
                              (e) => e.value.trackingNumber === tracking
                            )
                          : options?.at(0)
                      }
                    />
                  </div>
                </div>
                {selectedShipment ? (
                  <>
                    <ShipmentComponent
                      shipment={selectedShipment}
                      selected
                      key={selectedShipment.order}
                    />

                    <ShipmentStatusComponent
                      selectedShipment={selectedShipment}
                      setOpenNotificationModalForm={
                        setOpenNotificationModalForm
                      }
                    />
                    <div className={classes.details}>
                      <ShipmentDetails selectedShipment={selectedShipment} />

                      <div className={classes.itemsContainer}>
                        <Title size="m" className={classes.dropdownTitle}>
                          Shipped Items
                        </Title>
                        <div className={classes.items}>
                          {selectedShipment.shipmentPackages?.length !== 0 ? (
                            selectedShipment.shipmentPackages?.map(
                              (shipmentPackage) => {
                                return (
                                  <div
                                    key={shipmentPackage.id}
                                    className={classes.item}
                                  >
                                    <ShipmentPackageComponent
                                      shipmentPackage={shipmentPackage}
                                    />
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <span className={classes.noItem}>
                              No items on the selected shipment
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={classes.details}>Please select a package</div>
                )}
              </div>
              {portalLookAndFeel.mobileHtmlBanner && (
                <div
                  className={classes.homeBanner}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: portalLookAndFeel.mobileHtmlBanner,
                  }}
                />
              )}
              <FooterContainer />
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderPage;
