import Entity, { EntityOptions } from "./Entity";

export type ShipmentNotificationOptions = EntityOptions & {
  shipmentId: string;
  shipment: string;
  notificationChannel: string;
  name: string;
  email: string;
  phoneNumber: string;
  status: string;
};

export default class ShipmentNotification extends Entity<ShipmentNotificationOptions> {
  shipmentId?: string;

  shipment?: string;

  notificationChannel?: string;

  name?: string;

  email?: string;

  phoneNumber?: string;

  status?: string;

  constructor(options: ShipmentNotificationOptions) {
    super(options);
    this.set(options);
  }
}
