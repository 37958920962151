import { createContext, ReactNode, useMemo, useState } from "react";
import Shipment from "../../entities/Shipment";

export const PopulatedShipmentsContext = createContext<{
  populatedShipments?: Shipment[];
  setPopulatedShipments: React.Dispatch<
    React.SetStateAction<Shipment[] | undefined>
  >;
  error?: Error;
  setError: (prevState?: Error) => void;
  isLoading: boolean;
  setIsLoading: (prevState: boolean) => void;
  showNotificationsButton: boolean;
  setShowNotificationsButton: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  setShowNotificationsButton: () => {},
  setPopulatedShipments: () => {},
  setError: () => {},
  isLoading: false,
  showNotificationsButton: false,
  setIsLoading: () => {},
});

const PopulatedShipmentsProvider = ({ children }: { children: ReactNode }) => {
  const [populatedShipments, setPopulatedShipments] = useState<Shipment[]>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [showNotificationsButton, setShowNotificationsButton] = useState(false);

  const value = useMemo(
    () => ({
      populatedShipments,
      setPopulatedShipments,
      error,
      setError,
      isLoading,
      setIsLoading,
      showNotificationsButton,
      setShowNotificationsButton,
    }),
    [error, isLoading, populatedShipments, showNotificationsButton]
  );

  return (
    <PopulatedShipmentsContext.Provider value={value}>
      {children}
    </PopulatedShipmentsContext.Provider>
  );
};

export default PopulatedShipmentsProvider;
