/* eslint-disable react/button-has-type */
import classNames from "classnames";
import React from "react";
import classes from "./Button.module.scss";

export type ButtonKind = "primary" | "secondary" | "tertiary";

export type ButtonType = "button" | "reset" | "submit";
export type ButtonProps = {
  children: React.ReactNode;
  kind?: ButtonKind;
  type?: ButtonType;
  isFullWidth?: boolean;
} & React.HTMLProps<HTMLButtonElement>;

const Button = ({
  type = "button",
  kind = "secondary",
  disabled = false,
  children,
  className = "",
  isFullWidth = false,
  ...otherProps
}: ButtonProps) => {
  return (
    <>
      <button
        type={type}
        className={classNames(classes.button, classes[kind], className, {
          [classes.disabled]: disabled,
          [classes.isFullWidth]: isFullWidth,
        })}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
