import { createContext, ReactNode, useMemo, useState } from "react";
import Shipment from "../../entities/Shipment";

export const ShipmentsContext = createContext<{
  shipments?: Shipment[];
  setShipments: (prevState?: Shipment[]) => void;
  error?: Error;
  setError: (prevState?: Error) => void;
  isLoading: boolean;
  setIsLoading: (prevState: boolean) => void;
}>({
  setShipments: () => {},
  setError: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

const ShipmentsProvider = ({ children }: { children: ReactNode }) => {
  const [shipments, setShipments] = useState<Shipment[]>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(
    () => ({
      shipments,
      setShipments,
      error,
      setError,
      isLoading,
      setIsLoading,
    }),
    [error, isLoading, shipments]
  );

  return (
    <ShipmentsContext.Provider value={value}>
      {children}
    </ShipmentsContext.Provider>
  );
};

export default ShipmentsProvider;
