import { useCallback } from "react";

import ShipmentNotification from "../entities/ShipmentNotification";
import useNetSuiteService from "./useNetSuiteService";

export type ShipmentNotifications = {
  id: string;
  custrecord_ucp_shipment_not: string;
  custrecord_ucp_notification_channel: string;
  custrecord_ucp_name: string;
  custrecord_ucp_notification_email: string;
  custrecord_ucp_phone: string;
  custrecord_ucp_notification_status: string;
};

const useShipmentNotificationsService = () => {
  const { getFromNetSuite } = useNetSuiteService<ShipmentNotifications>({
    resource: "customrecord_ucp_shipping_notifications",
  });

  const getByShipmentIds = useCallback(
    async (shipmentIds) => {
      const body = await getFromNetSuite({
        filters: [["custrecord_ucp_shipment_not", "anyof", ...shipmentIds]],
        columns: [
          "custrecord_ucp_shipment_not",
          "custrecord_ucp_notification_channel",
          "custrecord_ucp_name",
          "custrecord_ucp_notification_email",
          "custrecord_ucp_phone",
          "custrecord_ucp_notification_status",
        ],
      });

      const results = body.results.map(
        (detail) =>
          new ShipmentNotification({
            shipmentId: detail.id,
            shipment: detail.custrecord_ucp_shipment_not,
            notificationChannel: detail.custrecord_ucp_notification_channel,
            name: detail.custrecord_ucp_name,
            email: detail.custrecord_ucp_notification_email,
            phoneNumber: detail.custrecord_ucp_phone,
            status: detail.custrecord_ucp_notification_status,
          })
      );

      return { results };
    },
    [getFromNetSuite]
  );
  return { getByShipmentIds };
};

export default useShipmentNotificationsService;
